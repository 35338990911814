import React, { Fragment, useState } from "react";
import { Line, Pie } from 'react-chartjs-2';
import * as XLSX from "xlsx";

const HistoricalMetrics = ({ metrics, selectedOptionInit, onUpdateMetrics}) => {
    console.log('-----METRICS HIS---→',  metrics);;
    const [selectedOption, setSelectedOption] = useState(selectedOptionInit);
    const data = {
        labels: metrics.area_data.labels,
        datasets: [
            {
            data: metrics.area_data.data,
            backgroundColor: ['#4fedec', '#00bfd5', '#0093b9', '#b92600', '#00b983', '#8300b9'],
            hoverBackgroundColor: ['#4fedec9c', '#00bfd59e', '#0093b9a3', '#7fdcc1', '#dc7fc9'],
            },
        ]
    };

    const dataOptions = {
        legend: {
            display: true, // Habilitar la visualización de la leyenda
            position: 'chartArea', // Colocar la leyenda dentro del área del gráfico
            labels: {
              boxWidth: 10, // Ancho de la caja de color de la leyenda
              fontSize: 12, // Tamaño de fuente de la leyenda
            },
        },
    }

    let dataLine = {
        labels: [],
        datasets: [
            {
                label: 'Conversaciones',
                data: [],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
            },
        ],
    };
    if(metrics.volume_data && metrics.volume_data.ordered_dates){
        metrics.volume_data.ordered_dates.forEach(key => {
          const value = metrics.volume_data[key];
          dataLine.labels.push(value.label);
          dataLine.datasets[0].data.push(value.data);
        });
    }else{
        console.log("metrics: ", metrics);
    }
    const options = {
        scales: {
        y: {
            beginAtZero: true,
        },
        },
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        onUpdateMetrics(event.target.value);
    };

    // Exportar contactos
    const exportToExcel = async () => {
        console.log('▶:', selectedOption)
        let data = metrics;
        const jsonArray1 = [
            {
                "Conversaciones recibidas": data.waitCount ||  0,
                "Conversaciones atendidas": data.closed ||  0,
                "Conversaciones sin atenter": data.onHold || 0,
                "Tiempo de atención": data.atentionTime || 0,
                "Tiempo de espera": data.waitTime || 0
            }
        ];
        console.log('DATA Fiiinal a exportar:', jsonArray1)
        
        const jsonArray2 = data.area_data.labels.map((label, index) => ({
            "Área": label,
            "Tiempo": data.area_data.data[index]
        }));
        console.log('DATA Fiiinal a exportar JSON 2:', jsonArray2)

         // Nombres de las hojas
        const sheetNames = ["Hoy", "Últimos 7 días", "Últimos 15 días", "Últimos 30 días", "Últimos 60 días"];
        let sheetName = sheetNames[selectedOption] || "Sheet1";

        // Crear workbook y worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(jsonArray1);

        // Obtener el rango de la primera tabla
        let lastRow = Object.keys(jsonArray1).length + 3; // Añadimos 2 para dejar un espacio entre tablas

        // Agregar la segunda tabla debajo de la primera
        XLSX.utils.sheet_add_json(worksheet, jsonArray2, { origin: `A${lastRow}` });

        // Agregar la hoja al workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

        // Descargar el archivo
        XLSX.writeFile(workbook, `Métricas historicas (${sheetName}).xlsx`);
    };

    return (
    <Fragment>
        <div div className="content-2 historical-metrics">
            <header className="text-center">
                <h2 className="m-0">Métricas Historicas</h2>
            </header>
            <div className="text-right d-flex justify-content-end align-items-center">
                <button
                    id="inventory-button"
                    className="btn btn-default mr-2"
                    onClick={exportToExcel}
                >
                    Descargar Métricas
                </button>
                <select name="select" id="select" className="form-control" value={selectedOption} onChange={handleSelectChange} style={{width: "15%", height: "26px", padding: "0"}}>
                    <option value="0" key="0" selected>Hoy</option>
                    <option value="1" key="1" >Últimos 7 días</option>
                    <option value="2" key="2" >Últimos 15 días</option>
                    <option value="3" key="3" >Últimos 30 días</option>
                    <option value="4" key="4" >Últimos 60 días</option>
                </select>
            </div>

            <div className="content-bubbles-historical d-flex justify-content-around align-items-center py-3 my-3" style={{backgroundColor: "#dceef7"}}>
                <div className="d-flex align-items-center">
                    <div className="drop" style={{backgroundColor: "#5bc0de"}}>
                        <span className="drop-value">{metrics.open + metrics.closed + metrics.onHold}</span>
                    </div>
                    <span className="ml-4">Conversaciones recibidas</span>
                </div>
                <div className="d-flex align-items-center">
                    <div className="drop" style={{backgroundColor: "#00a884"}}>
                        <span className="drop-value">{metrics.closed}</span>
                    </div>
                    <span className="ml-4">Atendidas</span>
                </div>
                <div className="d-flex align-items-center">
                    <div className="drop" style={{backgroundColor: "#f56f89"}}>
                        <span className="drop-value">{metrics.onHold}</span>
                    </div>
                    <span className="ml-4">Sin atender</span>
                </div>
            </div>

            <div className="content-graphics d-flex my-3">
                <div className="graphics-1 mr-3" style={{backgroundColor: "#dceef7", width: "50%"}}>
                    <h4 className="text-center p-3">Volumen de conversaciones</h4>
                    <div style={{width: "90%", margin: "0 auto 10px"}}>
                        <Line data={dataLine} options={options} />
                    </div>
                </div>


                <div className="graphics-2 d-flex justify-content-around ml-3" style={{backgroundColor: "#dceef7", width: "50%"}}>
                    <div className="content-times">
                        <div className="d-flex justify-content-center align-items-center" style={{height: "50%", textAlign: "center"}}>
                            <div>
                                <h4 className="text-center p-3">Tiempo de atención</h4>
                                <p style={{fontSize: "2rem"}}>{metrics.atentionTime}</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={{height: "50%", textAlign: "center"}}>
                            <div>
                                <h4 className="text-center p-3">Tiempo de espera</h4>
                                <p style={{fontSize: "2rem"}}>{metrics.waitTime}</p>
                            </div>
                        </div>
                    </div>

                    <div className="pie-graphic">
                        <div className="content-departament">
                            <h4 className="text-center p-3 mb-0">Atención por departamento</h4>
                            <div style={{ width: '300px', height: '300px' }}>
                                <Pie data={data} options={dataOptions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
    );
};

export default HistoricalMetrics;
