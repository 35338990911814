import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useAPI from "../../hooks/useAPI";
import Spinner from "../plugins/Spinner";
import Modal from "../layout/Modal";
import styled from "styled-components";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import Header from "../layout/Header";
import { useNavigate } from "react-router-dom";
import { FaTable } from "react-icons/fa";
import { BsPencilFill, BsTrash3 } from "react-icons/bs";
import { FaEllipsisVertical } from "react-icons/fa6";
import usePage from "../../hooks/usePage";
import Pagination from "../plugins/Pagination";


const AdditionalFields = () => {
  //Estado para almacenar y listar los campos
  const [fieldsList, setFieldsList] = useState([]);
  //Estado para el Loading
  const [loading, setLoading] = useState(false);
  //Estado para activar input para editar campo selecionado
  const [editField, setEditField] = useState(false);
  //Estado para almacenar campo a editar
  const [selectedField, setSelectedField] = useState([]);
  //Estado para almacenar el nuevo nombre del campo  editar
  const [newNameField, setNewNameField] = useState("");
  //Estado para abrir modal de crear campos
  const [modalCreated, setModalCreated] = useState(false);
  //Estado para modal de editar campo
  const [modalUpdate, setModalUpdate] = useState(false);

  /*************************************************************/
  //Ojeto para los tipos de campos
  const typeFields = [
    { name: "Texto", id: 1, value: "text" },
    { name: "Numérico", id: 2, value: "number" },
    { name: "Párrafo", id: 3, value: "textarea" },
    { name: "Fecha", id: 4, value: "date" },
  ];

  //Estado para leer los campos de tipo y nombre de campo
  const [typeField, setTypeField] = useState("");
  const [nameField, setNameField] = useState("");
  //Estado para mostrar el titulo y la lista de campos si existen
  const [viewTitle, setViewTitle] = useState(false);
  //Estado para almacenar los campos que se van creando y listarlos
  const [fieldList, setFieldList] = useState([]);
  //Estado para guardar la lista de campos nuevos
  const [saveFields, setSaveFields] = useState([]);
  //Estado para almacenar lista de campos ya creados
  const [fieldsCreated, setFieldsCreated] = useState([]);
  //Estado para validar si ya existe campo con el mismo nombre
  const [repeatedField, setRepeatedField] = useState(false);

  const [validation, setValidation] = useState(false);

  //Custoom Hooks
  const { page, byPage, resetPage, setPage } = usePage();
  const { urlAPI, urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();
  const token = localStorage.getItem("USER_TOKEN");

  const {watch,register,handleSubmit,formState: { errors },reset} = useForm({
    defaultValues: {
      id: "",
      bitByUpdate: "1",
      name: "",
      type: "",
    },
  });

  useEffect(() => {
    getFieldsList();
  }, []);

  const navigate = useNavigate();
  
  //--------------Funcion para listar campos--------------//
  const getFieldsList = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "aditionalFields";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options)
      const result = await response.json();
      console.log("result", result);
      // console.log("result final", result.data);
      // if (result.data.length === 0) {
      if (result.type != "success" || result.data.length <= 0) {
        setValidation(true);
        setLoading(false);
        console.log("sin datos en el sistema");
      } else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        Swal.fire({
          title: "¡Atención!",
          text: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales',
          icon: 'info',
        });
        navigate('/');
      } else {
        console.log("Si hay datos...........");
        setFieldsList(result.data);
        setFieldsCreated(result.data);
        setLoading(false);
        setValidation(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //--------------Funcion para pintar campo a editar con sus valores--------------//
  const onEditField = (id) => {
    setModalUpdate(true);
    setEditField(true);
    const editedField = fieldsList.filter((field) => field.id == id);
    console.log("campo a editar", editedField[0]);
    setSelectedField(editedField);
    reset({
      id: editedField[0].id,
      bitByUpdate: "1",
      name: editedField[0].name,
      type: editedField[0].type,
    });
  };

  //--------------Funcion para guardar campo editado--------------//
  const onUpdatedField = async (inputs) => {
    const { name, id, type } = inputs;
    try {
      const fieldToUpdated = {
        aditionalFields: [
          {
            id,
            bitByUpdate: "1",
            name,
            type,
          },
        ],
      };
      setLoading(true);
      const url = urlAPI_2 + "aditionalFields";
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(fieldToUpdated)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Data Updated", result);

      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
        confirmButtonText: "Aceptar",
      });
      setModalUpdate(false);
      setEditField(false);
      reset();
      getFieldsList();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  //--------------Funcion para eliminar un campo--------------//
  const onDeleteFieldList = async (id) => {
    try {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar este campo?",
        text: "Una vez eliminado, no se podra recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        const fieldToDelete = {
          aditionalFields: [
            {
              id: id,
              bitByDel: "1",
            },
          ],
        };
        setLoading(true);
        const newListFields = fieldsList.filter((field) => field.id !== id);
        console.log("Nueva lista de campos", newListFields);

        if (result.isConfirmed) {
          const url = urlAPI_2 + "aditionalFields";
          const options = { method: "DELETE", mode: "cors", body: JSON.stringify(fieldToDelete)};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          console.log("Data Eliminated", result);

          //# Confirmación de la eliminación
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
            confirmButtonText: "Aceptar",
          });

          setFieldsList(newListFields);
          setLoading(false);
          getFieldsList();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //--------------Funcion para guardar--------------//
  const postFieldNew = async (values) => {
    try {
      if (!typeField || !nameField) {
        Swal.fire({
          title: "¡Atención!",
          text: "Debes completar todos los campos",
          icon: "error",
        });
      } else {
        setModalCreated(false);
        Swal.fire({
          title: "¿Estas seguro que deseas modificar los campos adicionales?",
          text: "Podria invalidar listas con contactos cargados previamente si no tuvieran los nuevos campos",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Agregar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const saveFieldLists = {
              aditionalFields: [
                {
                  id: uuid(),
                  name: nameField,
                  type: typeField,
                  status: true,
                },
              ],
            };
            if (fieldsCreated) {
              const resultMap = fieldsCreated
                .map((element) => element.name.toLowerCase())
                .includes(nameField.toLowerCase());
              if (resultMap) {
                Swal.fire({
                  title: "¡Atención!",
                  text: "Ya existe un campo con este nombre",
                  icon: "error",
                });
              } else {
                setLoading(true);
                const url = urlAPI_2 + "aditionalFields";
                const options = { method: "POST", mode: "cors", body: JSON.stringify(saveFieldLists)};
                const response = await fetchWithAuth(url, options);
                const result = await response.json();
                Swal.fire({
                  title: "¡Bien!",
                  text: result.message,
                  icon: result.type,
                  confirmButtonText: "Ok",
                });
                setTypeField("");
                setNameField("");
                setFieldList([]);
                setViewTitle(false);
                getFieldsList();
              }
            } else {
              setLoading(true);
              const url = urlAPI_2 + "aditionalFields";
              const options = { method: "POST", mode: "cors", body: JSON.stringify(saveFieldLists)};
              const response = await fetchWithAuth(url, options);
              const result = await response.json();
              Swal.fire({
                title: "¡Bien!",
                text: result.message,
                icon: result.type,
                confirmButtonText: "Ok",
              });
              setTypeField("");
              setNameField("");
              setFieldList([]);
              setViewTitle(false);
              setModalCreated(false);
              getFieldsList();
            }
          }
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      <div className="col-xs-9 heading">
        <div>
          <h1>Campos adicionales</h1>
          <input
            type="button"
            className="btn btn-default"
            value="Agregar Nuevo"
            onClick={() => {
              setTypeField("");
              setNameField("");
              setFieldList([]);
              setViewTitle(false);
              setModalCreated(true);
              setEditField(false);
            }}
          />
        </div>

        <Header />
      </div>
      <div 
        className="container-fluid mt-3 px-0 align-items-center mb-2" 
        style={{ 
          // display: validation ? 'inline-block' : 'flex', 
          height: "80%", 
          overflowY: "auto" 
        }}
      >
        <table className="table table-striped">
          <thead>
            <tr key="">
              <th>Acciones</th>
              <th>Nombre</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody style={{ width: "1170px" }}>
            {/* {fieldsList && fieldsList.map((field, k) => ( */}
            {fieldsList.length > 0 && fieldsList.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((field, k) => (
              <tr key={field.id}>
                <td>
                  <div className="row justify-content-around">
                    <div className="dropdown">
                      <button
                        className="bg-transparent border-0"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <FaEllipsisVertical className="text-success f-24 pointer" />
                      </button>
                      <div
                        className="dropdown-menu p-2"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          onClick={() => onEditField(field.id)}
                          className="text-primary border-0 bg-transparent d-block pointer mt-2 w-100 text-left"
                        >
                          <BsPencilFill style={{ fontSize: "18px" }}/>
                          <span className="text-dark ml-2 f-14">Editar</span>
                        </button>
                        <button
                          onClick={() => onDeleteFieldList(field.id)}
                          className="text-danger border-0 bg-transparent d-block pointer mt-2 w-100 text-left"
                        >
                          <BsTrash3 style={{ fontSize: "18px" }} />
                          <span className="text-dark ml-2 f-14">
                            Eliminar
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{field.name}</td>
                <td>
                  {field.type === "text"
                    ? "Texto"
                    : field.type === "number"
                    ? "Numerico"
                    : field.type === "textarea"
                    ? "Parrafo"
                    : field.type === "date"
                    ? "Fecha"
                    : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {validation && (
          <div className="text-center mt-3">
            <div className="empty">
              <FaTable style={{ fontSize: "55px" }} />
              <h1>No tienes campos adicionales</h1>
              <input
                type="button"
                className="btn btn-default"
                value="Agregar Nuevo"
                onClick={() => {
                  setTypeField("");
                  setNameField("");
                  setFieldList([]);
                  setViewTitle(false);
                  setModalCreated(true);
                  setEditField(false);
                }}
              />
            </div>
          </div>
        )}
      </div>

      <footer>
          <div className="row">
            <div className="col-md-8">
              {/* <label>Para los elementos seleccionados:</label>
              <select className="form-control">
                <option>Selecciona una acción</option>
                <option>Ocultar</option>
                <option>Eliminar</option>
              </select>
              <button type="submit" className="btn btn-default">Aplicar</button> */}
            </div>

            <div className="col-md-4">
              {/* Creación de la paginación simple */}
              <Pagination
                totalData={fieldsList.length}
                byPage={byPage}
                setPage={setPage}
                // onLastPage={fetchPersonsPaged}
              />
            </div>
          </div>
        </footer>

      <Modal
        status={modalCreated}
        changeStatus={setModalCreated}
        title="Crear campo adicional"
        // action={cleanInputs}
        width={"830px"}
      >
        <Content>
          <div className="sc-fnykZs Nivgn container-fluid mt-0 px-0 mb-3">
            <form key="3223223" className="left-content add-fields">
              <div className="form-content">
                <div className="col-md-4">
                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                    <label htmlFor="select">Tipo de campo: </label>
                    <select
                      name="select"
                      id="select"
                      className="form-control"
                      value={typeField}
                      onChange={(e) => {
                        setTypeField(e.target.value);
                        setNameField("");
                      }}
                    >
                      <option value="" key="0" disabled>
                        Selecciona...
                      </option>
                      {typeFields.map((element) => (
                        <option value={element.value} key={element.id}>
                          {element.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                    <label>Nombre del campo: </label>
                    <div className="su-control-group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        value={nameField}
                        onChange={(e) => {
                          setNameField(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {nameField ? (
                  <div className="col-md-4">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label>{nameField}: </label>
                      <div className="su-control-group">
                        {typeField === "textarea" ? (
                          <textarea
                            name={typeField}
                            className="form-control"
                            readOnly="readonly"
                            placeholder="Aquí podras digitar textos largos"
                          ></textarea>
                        ) : (
                          <input
                            type={typeField}
                            name={typeField}
                            id={typeField}
                            className="form-control"
                            readOnly="readonly"
                            placeholder={
                              typeField == "date"
                                ? "dd/mm/aaaa"
                                : typeField == "number"
                                ? "123456789"
                                : typeField == "text"
                                ? "Texto"
                                : null
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </form>
          </div>

          <div
            className="row justify-content-end"
            style={{
              borderTop: "1px solid #e8e8e8",
              paddingTop: "15px",
              width: "100%",
              textAlign: "end",
            }}
          >
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => setModalCreated(false)}
            >
              Volver
            </a>
            <input
              onClick={postFieldNew}
              className="btn btn-lg btn-info"
              value="Guardar"
              name="submit"
              id="submit"
              type="submit"
            />
          </div>
        </Content>
      </Modal>

      <Modal
        status={modalUpdate}
        changeStatus={setModalUpdate}
        title="Editar campo adicional"
        width={"830px"}
      >
        <Content>
          <div className="sc-fnykZs Nivgn container-fluid mt-0 px-0 mb-0">
            <form
              key="3223223"
              className="left-content add-fields"
              onSubmit={handleSubmit(onUpdatedField)}
            >
              <div className="form-content" style={{ margin: "5px auto 16px" }}>
                <div className="col-md-4">
                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                    <label htmlFor="select">Tipo de campo: </label>
                    <select
                      name="type"
                      id="type"
                      className="form-control"
                      onChange={(e) => {
                        setTypeField(e.target.value);
                        setNameField("");
                      }}
                      {...register("type", {
                        required: {
                          value: true,
                          message: "El tipo de campo es requerido.",
                        },
                      })}
                    >
                      <option value="" key="0" disabled>
                        Selecciona...
                      </option>
                      {typeFields.map((element) => (
                        <option value={element.value} key={element.id}>
                          {element.name}
                        </option>
                      ))}
                    </select>
                    {errors.name && <Alert>* {errors.name.message}</Alert>}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                    <label>Nombre del campo: </label>
                    <div className="su-control-group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        onChange={(e) => {
                          setNameField(e.target.value);
                        }}
                        {...register("name", {
                          required: {
                            value: true,
                            message: "El nombre es requerido.",
                          },
                        })}
                      />
                    </div>
                    {errors.name && <Alert>* {errors.name.message}</Alert>}
                  </div>
                </div>

                {!nameField === "" ? (
                  <div className="col-md-4">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label>{nameField}: </label>
                      <div className="su-control-group">
                        {typeField === "textarea" ? (
                          <textarea
                            name={typeField}
                            className="form-control"
                            readOnly="readonly"
                            placeholder="Aquí podras digitar textos largos"
                          ></textarea>
                        ) : (
                          <input
                            type={typeField}
                            name={typeField}
                            id={typeField}
                            className="form-control"
                            readOnly="readonly"
                            placeholder={
                              typeField == "date"
                                ? "dd/mm/aaaa"
                                : typeField == "number"
                                ? "123456789"
                                : typeField == "text"
                                ? "Texto"
                                : null
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div
                className="row justify-content-end"
                style={{
                  borderTop: "1px solid #e8e8e8",
                  paddingTop: "15px",
                  width: "100%",
                  textAlign: "end",
                  margin: "0 auto",
                }}
              >
                <a
                  href="javascript:;"
                  className="btn btn-lg btn-default mr-1"
                  onClick={() => {
                    reset({ name: "", type: "" });
                    setModalUpdate(false);
                  }}
                >
                  Volver
                </a>
                <input
                  className="btn btn-lg btn-info"
                  value="Guardar"
                  name="submit"
                  id="submit"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </Content>
      </Modal>
    </Fragment>
  );
};

export default AdditionalFields;

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    vertical-align: top;
    border-radius: 3px;
  }

  //   label {
  //     font-weight: 900;
  //     font-size: 18px;
  //   }

  input {
    padding: 10px;
    // border-radius: 10px;
    font-size: 17px;
  }
`;
