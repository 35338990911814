import React, { useState, useEffect, useRef } from "react";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import Spinner from "../plugins/Spinner";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { BsPencilFill, BsTrash3, BsPlusSquare } from "react-icons/bs";
import { LuCalendarClock } from "react-icons/lu";
import { TbCalendarCancel } from "react-icons/tb";
import styled from "styled-components";
import './css/historicalChat.css';

const HistoricalChats = () => {
  const [companie, setCompanie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatHistoricsValue, setChatHistoricsValue] = useState("");
  const [enabledHistorics, setEnabledHistorics] = useState([]);
  const enabledHistoricsRef = useRef(false);
  const { urlAPI_2, fetchWithAuth } = useAPI();
  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({defaultValues: {}});
  const [formDataDownload, setFormDataDownload] = useState({
    type: 'csv',
    fechaDesde: '',
    fechaHasta: ''
  });
  const token = localStorage.getItem("USER_TOKEN");

  useEffect(() => {
    getCompanieHours();
  }, []);

  useEffect(() => {
    console.log("enabledHistorics changed: ", enabledHistorics);
    enabledHistoricsRef.current = enabledHistorics;
  }, [enabledHistorics]);

  const getCompanieHours = async () => {
    const resultIdCompany = localStorage.getItem("ID_COMPANY");
    setLoading(true);
    try {
      const url = urlAPI_2 + "companies/" + resultIdCompany;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        setCompanie([]);
      } else {
        setCompanie(result.data[0]);
        const {
          chatHistorics
        } = result.data[0];
        console.log("chatHistorics: ", chatHistorics);
        setChatHistoricsValue(chatHistorics.value);
        setEnabledHistorics(chatHistorics.enabled);
        enabledHistoricsRef.current = chatHistorics.enabled;
        reset({
            historicValue: chatHistorics.value,
            enabledHistorics: chatHistorics.enabled,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const putCompanyHistorics = async (inputs) => {
    const { historicValue, enabledHistorics } = inputs;
    if(enabledHistoricsRef.current && !historicValue){
      Swal.fire({
        title: "¡Atención!",
        title: "Debes seleccionar un límite para el guardado de los chats recientes.",
        icon: "info",
      });
      return;
    }
    const confirmResult = await Swal.fire({
      title: 'Confirmación',
      text: 'Esta acción moverá los mensajes a un almacenamiento de históricos según el ajuste seleccionado. Luego no podrás verlos en la pantalla del chat multiagente. ¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    });

    if (confirmResult.isConfirmed) {
      const values_update = {
        chatHistorics: {enabled: enabledHistoricsRef.current, value: historicValue}
      };

      const id = companie._id;
      try {
        setLoading(true);
        const url = urlAPI_2 + "companies/" + id;
        const options = { method: "PUT", mode: "cors", body: JSON.stringify(values_update)};
        const response = await fetchWithAuth(url, options);
        const result = await response.json();
        Swal.fire({
          title: "¡Bien!",
          title: result.message,
          icon: result.type,
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInputChangeDownloadHistorics = (event) => {
    const { name, value } = event.target;
    setFormDataDownload({
      ...formDataDownload,
      [name]: value
    });
  };

  const handleSubmitDownloadHistorics = (event) => {
    event.preventDefault();
    downloadHistorics(formDataDownload);
  };

  const showMoreInfo = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Más información',
      html: `
        <p style="margin-top: 1rem">Al habilitarse, los chats recientes comenzarán a almacenarse durante los últimos días seleccionados.</p>
        <p style="margin-bottom: 0px">Por ejemplo, si selecciona 30 días solo podrá visualizar chats de los últimos 30 días en la sección de Chats.</p>
        <p style="margin-bottom: 0px">Para acceder a los chats del resto de los días deberá descargarlos.</p>
      `,
      icon: 'info',
      confirmButtonText: 'Cerrar'
    });    
  }

  const downloadHistorics = async (inputs) => {
    const { type, fechaDesde, fechaHasta } = inputs;
    let data_post = { type: type, fechaDesde: fechaDesde, fechaHasta: fechaHasta };
    setLoading(true);
    try {
      const url = urlAPI_2 + "downloadHistorics";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(data_post)};
      const response = await fetchWithAuth(url, options);
      if (!response.ok) {
        throw new Error('Error al realizar la solicitud.');
      }
  
      // Extraer headers para obtener el nombre del archivo
      const contentDisposition = response.headers.get('Content-Disposition');
      const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
  
      if (fileNameMatch && fileNameMatch[1]) {
        // Encontrar el nombre del archivo desde el header Content-Disposition
        const fileName = fileNameMatch[1];
  
        // Obtener el blob del contenido de la respuesta
        const blob = await response.blob();
  
        // Crear un enlace para descargar el archivo
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
  
        // Liberar recursos
        URL.revokeObjectURL(downloadLink.href);
      } else {
        throw new Error('No se pudo obtener el nombre del archivo para la descarga.');
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: "¡Atención!",
        text: 'Hubo un problema al realizar la solicitud. Por favor, intenta de nuevo más tarde.',
      });
    } finally {
      setLoading(false);
    }
  };  

  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div>
            <h1>Chats históricos</h1>
          </div>

          <Header />
        </div>

        <div className="container-fluid mt-3 px-0 d-flex align-items-center" style={{ height: "90%" }}>
          <section id="content-historical-chats" className="h-100 w-100 mt-3" style={{overflow:"auto"}}>
            <section className="module-create-user myData" style={{ height: "auto", backgroundColor: "transparent", boxShadow: "none" }}>
              <div>
                <form onSubmit={handleSubmit(putCompanyHistorics)}>
                  <div className="sections-my-data">
                    <div className="d-flex align-items-center">
                      <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                        Configuración
                      </h2>
                      <input
                          name="messageCheck"
                          id="messageCheck"
                          type="checkbox"
                          value={enabledHistorics}
                          className="cmn-toggle cmn-toggle-round-flat"
                          checked={enabledHistorics}
                          {...register("enabledHistorics")}
                          onChange={(e) => {setEnabledHistorics(e.target.checked)}}
                      />
                      <label className="ml-4 mb-0" htmlFor="messageCheck"></label>
                    </div>
                    {enabledHistorics &&
                      <div className="module-section border-top-0" id="product-basic">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row" style={{flexDirection: "column"}}>
                              <div className="col-md-6 my-0 mx-auto d-flex align-items-end">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
                                  <label htmlFor="select">Seleccione la cantidad de días para el guardado de chats recientes: </label>
                                  <select
                                    name="historicValue"
                                    id="historicValue"
                                    className="form-control"
                                    {...register("historicValue")}
                                    onChange={(e) => {setChatHistoricsValue(e.target.value)}}
                                  >
                                    <option value="" key="0" disabled selected>
                                      Seleccione...
                                    </option>
                                    <option value="30" key="30" >
                                      30 días.
                                    </option>
                                    <option value="60" key="60" >
                                      60 días.
                                    </option>
                                    <option value="90" key="90" >
                                      90 días.
                                    </option>
                                    <option value="180" key="180" >
                                      180 días.
                                    </option>                                                                   
                                  </select>
                                </div>

                                {chatHistoricsValue &&
                                  <div className="ml-2 d-flex align-items-end">
                                    <BsTrash3 className="text-danger pointer" style={{fontSize: "18px"}} onClick={() => {setChatHistoricsValue(""); reset({historicValue: "", enabledHistorics: false})}}/>
                                  </div>
                                }
                              </div>
                              <div className="col-md-6 my-0 mx-auto mt-1">
                                  <button className="custom-button" onClick={showMoreInfo}>
                                      Más información
                                  </button>
                              </div>                            
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <footer className="mx-0">
                      <div className="row">
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6 right">
                          <input
                            className="btn btn-lg btn-info"
                            value="Actualizar"
                            type="submit"
                          />
                        </div>
                      </div>
                    </footer>
                  </div>

                </form>
              </div>
            </section>
            <section className="module-create-user myData" style={{ height: "auto", backgroundColor: "transparent", boxShadow: "none" }}>
              <div>
                <form onSubmit={handleSubmitDownloadHistorics}>
                  <div className="sections-my-data">
                    <div className="d-flex align-items-center">
                      <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                        <TbCalendarCancel style={{ fontSize: "25px", marginRight: "10px" }} />
                        Descarga de chats
                      </h2>
                    </div>
                    <div className="module-section border-top-0" id="product-basic">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row" style={{flexDirection: "column"}}>
                              <div className="col-md-6 my-0 mx-auto d-flex align-items-center">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
                                  <label htmlFor="select">Seleccione el rango de fechas: </label>
                                  <div className="row">
                                    <div className="col-md-6 su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                      <label htmlFor="FechaDesde">Desde: </label>
                                      <div className="su-control-group">
                                        <input
                                          className="form-control"
                                          name="fechaDesde"
                                          type="date"
                                          value={formDataDownload.fechaDesde}
                                          onChange={handleInputChangeDownloadHistorics}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                      <label htmlFor="FechaHasta">Hasta: </label>
                                      <div className="su-control-group">
                                        <input
                                          className="form-control"
                                          name="fechaHasta"
                                          type="date"
                                          value={formDataDownload.fechaHasta}
                                          onChange={handleInputChangeDownloadHistorics}
                                        />
                                      </div>
                                    </div>                                  
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 my-0 mx-auto d-flex align-items-center">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
                                  <label htmlFor="select">Seleccione el formato: </label>
                                  <select
                                    className="form-control"
                                    name="type"
                                    value={formDataDownload.type}
                                    onChange={handleInputChangeDownloadHistorics}
                                  >
                                    <option value="json">JSON</option>
                                    <option value="csv">CSV</option>
                                  </select>
                                </div>
                              </div>                       
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    <footer className="mx-0">
                      <div className="row">
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6 right">
                          <input
                            className="btn btn-lg btn-info"
                            value="Descargar"
                            type="submit"
                          />
                        </div>
                      </div>
                    </footer>
                  </div>
                </form>
              </div>
            </section>
          </section>          
        </div>     
      </div>
    </>
  );
};

export default HistoricalChats;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 180px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  // .content-img .icon-eye {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .content-img img:hover {
    opacity: 0.6;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 250px;
`;
