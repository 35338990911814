import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Header from "../layout/Header";
import styled from "styled-components";
import Swal from "sweetalert2";
import Modal from "../layout/Modal";
import Spinner from "../plugins/Spinner";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Picker from "emoji-picker-react";
import { useForm } from "react-hook-form";
import useAPI from "../../hooks/useAPI";

import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  updateEdge,
} from "react-flow-renderer";

const initialNodes = [
  {
    id: "1",
    type: "input",
    data: {
      label: "Saludo inicial",
    },
    position: { x: 0, y: 0 },
  },
];

const initialEdges = [{ id: "e1-2", source: "1", target: "2" }];

const onInit = (reactFlowInstance) =>
  console.log("flow loaded:::::::", reactFlowInstance);

const ChatBot = () => {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("list");
  const [modalCreateAnswer, setModalCreateAnswer] = useState(false);
  const [action, setAction] = useState(false);
  const [botName, setBotName] = useState("");
  const [descriptionBot, setDescriptionBot] = useState("");
  const [headerBot, setHeaderBot] = useState({});

  const [isInfo, setIsInfo] = useState(false);
  const [check, setCheck] = useState("");

  // const [elements, setElements] = useState(defaultNodes);
  const [nameElement, setNameElement] = useState("");
  const [userResponse, setUserResponse] = useState("");
  const [returnMenu, setReturnMenu] = useState("");
  const [userResponseNot, setUserResponseNot] = useState(false);
  const [inputStrNot, setInputStrNot] = useState(false);

  const [viewOrEdit, setViewOrEdit] = useState(false);
  const [createResponse, setCreateResponse] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [createOrEdit, setCreateOrEdit] = useState("");

  const edgeUpdateSuccessful = useRef(true);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [selectedNode, setSelectedNode] = useState("");

  const { urlAPI_2, fetchWithAuth } = useAPI();

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    name: "",
    description: "",
    messageAnswer: "",
    status: false,
  });

  useEffect(() => {
    getUsersSystem();
  }, []);

  // Para obtener el id de la compañia
  const getUsersSystem = async () => {
    try {
      // setLoading(true);
      const url = urlAPI_2 + "users";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API", result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (!result.data) {
        // setValidation(true);
      } else {
        localStorage.setItem("ID_COMPANY", result.idCompany);
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
    console.log("edges", edges);
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }
    edgeUpdateSuccessful.current = true;
    console.log("edges", edges);
  }, []);

  const edgesWithUpdatedTypes = edges.map((edge) => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find((node) => node.type === "custom").data
        .selects[edge.sourceHandle];
      edge.type = edgeType;
    }

    return edge;
  });

  const onAddNode = () => {
    if (!userResponse) {
      setUserResponseNot(true);
      console.log("No hay respuesta del usuario");
    } else if (!inputStr) {
      console.log("No hay respuesta del bot");
      setUserResponseNot(false);
      setInputStrNot(true);
    } else {
      console.log("Si hay Datos");
      var ob = {
        userResponse: userResponse,
        returnMenu: returnMenu,
        botResponse: inputStr,
      };
      console.log("resultado de agregar nuevo node:::::", ob);
      setUserResponseNot(false);
      setInputStrNot(false);
      setNodes((e) =>
        e.concat({
          id: (e.length + 1).toString(),
          data: {
            label: `${nameElement}`,
            responseUser: userResponse,
          },
          type: "default",
          position: {
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
          },
          userResponse: userResponse,
          returnMenu: returnMenu,
        })
      );
      setNameElement("");
      setInputStr("");
      setUserResponse("");
      setReturnMenu(false);
      setModalCreateAnswer(false);
    }
  };

  // Evento al seleccionar un Node
  const onClickNode = (e) => {
    console.log("Node seleccionado....", e.target.dataset.id);
    console.log("Lista de Nodes....", nodes);
    const nodeId = e.target.dataset.id;
    const res = nodes.filter((n) => n.id === nodeId);
    console.log("resultado del filtro...", res);
    const { data, id } = res[0];
    setSelectedNode(e.target.dataset.id);
    setUserResponse(data.responseUser);
    setInputStr(data.label);
    setCreateOrEdit("Editar");
    setAction(true);
    // setModalCreateAnswer(true);
  };

  // Editar Node
  const onEditNode = () => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNode) {
          node.data = {
            ...node.data,
            label: inputStr,
            responseUser: userResponse,
          };
        }

        return node;
      })
    );
    setNameElement("");
    setInputStr("");
    setModalCreateAnswer(false);
  };

  // Eliminar Node
  const onDeleteNode = () => {
    Swal.fire({
      title: "¿Estas seguro que deseas eliminar este mensaje?",
      text: "No podrás recuperarlo.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = nodes.filter((n) => n.id != selectedNode);
        console.log("resultado del filtro...", res);
        setNodes(res);
        setNameElement("");
        setInputStr("");
        setModalCreateAnswer(false);

        //# Confirmación de la eliminación
        // Swal.fire({
        //   title: result.message,
        //   icon: result.type,
        //   confirmButtonText: "Aceptar",
        // });
      }
    });
  };

  // Evento al seleccionar una linea
  const onClickEdge = (e) => {
    console.log("Linea seleccionadaaaaa...", e);
  };

  //Evento para agregar emojis
  const onEmojiClick = (event, emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    // setShowPicker(false);
  };

  // Guardar información del bot
  const onSaveInfoBot = (inputs) => {
    console.log("Guardando info del bot");
    const resultIdCompany = localStorage.getItem("ID_COMPANY");
    const { name, description, messageAnswer, status } = inputs;
    console.log("status:::::", status);

    var header = {
      idCompany: resultIdCompany,
      nameBot: name,
      description: description,
      menssageInitial: messageAnswer,
      isActive: status,
    };
    setBotName(name);
    setDescriptionBot(description);
    setHeaderBot(header);

    console.log("Objeto del header a guardar", header);
    Swal.fire({
      title: "¡Bien!",
      text: "Datos guardados correctamente",
      icon: "success",
      confirmButtonText: "Aceptar",
    });
    // setMode("create");
    setViewOrEdit(true);
  };

  // Guardar info y estructura del bot
  const onSaveBot = () => {
    // console.log("Lista de campos....", nodes);
    // console.log("lista de lineas....", edges);
    var objFinal = {
      header: headerBot,
      body: {
        nodes: nodes,
        edges: edges,
      },
    };

    console.log("OBJETO FINAL:::", objFinal);
  };

  // const yourFunction2 = (t) => {
  //   console.log("eliminandooooooooooo", t);
  // };

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      {mode === "list" ? (
        <div className="m-auto container-fluid p-0">
          <div className="col-xs-9 heading">
            <div>
              <h1>ChatBot</h1>
            </div>
            <Header />
          </div>

          <div
            className="container-fluid mt-3 px-0"
            style={{
              overflowX: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <section className="module-create-user chat-bot">
              <header
                onClick={() => {
                  if (isInfo) {
                    setIsInfo(false);
                  } else {
                    setIsInfo(true);
                  }
                }}
              >
                <h1>
                  <i
                    className="icon-reddit mr-2"
                    style={{ fontSize: "25px" }}
                  ></i>{" "}
                  Bot
                </h1>
                {isInfo ? (
                  <i className="icon-minus-circle"></i>
                ) : (
                  <i className="icon-plus-circle"></i>
                )}
              </header>

              {isInfo ? (
                <div className="bg-white">
                  <div>
                    <form
                      id="form_save_payu"
                      onSubmit={handleSubmit(onSaveInfoBot)}
                    >
                      <div className="content mx-5">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="row mb-3">
                              <div className="col-md-6">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                  <label htmlFor="name">Nombre: </label>
                                  <div className="su-control-group">
                                    <input
                                      className="form-control"
                                      id="name"
                                      name="name"
                                      type="text"
                                      {...register("name", {
                                        required: {
                                          value: true,
                                          message: "El nombre es requerido.",
                                        },
                                      })}
                                    />
                                  </div>
                                  {errors.name && (
                                    <Alert>* {errors.name.message}</Alert>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                  <label htmlFor="email">Descripción: </label>
                                  <div className="su-control-group">
                                    <input
                                      className="form-control"
                                      id="description"
                                      name="description"
                                      type="text"
                                      placeholder="Opcional"
                                      {...register("description")}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                  <label htmlFor="email">
                                    Mensaje a respuesta no encontrada:{" "}
                                  </label>
                                  <div className="su-control-group">
                                    <textarea
                                      name="messageAnswer"
                                      className="form-control"
                                      id="messageAnswer"
                                      {...register("messageAnswer", {
                                        required: {
                                          value: true,
                                          message: "El mensaje es requerido.",
                                        },
                                      })}
                                    ></textarea>
                                  </div>
                                  {errors.messageAnswer && (
                                    <Alert>
                                      * {errors.messageAnswer.message}
                                    </Alert>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6 d-flex align-items-center justify-content-center">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                  <div className="su-control su-switch mt-2">
                                    <div className="switch">
                                      <input
                                        id="status"
                                        className="cmn-toggle cmn-toggle-round-flat"
                                        type="checkbox"
                                        name="status"
                                        // onChange={(e) => {
                                        //   setCheck(e.target.checked);
                                        // }}
                                        {...register("status")}
                                      />
                                      <label htmlFor="status"></label>
                                    </div>
                                    <label
                                      className="form-check-label f-15 f-w-400 ml-3"
                                      htmlFor="status"
                                    >
                                      {check === true
                                        ? "Desactivar Bot"
                                        : "Activar Bot"}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="su-control su-message">
                              <h4 className="title">
                                <i className="icon-info-circled"></i>Bot
                              </h4>
                              <div>
                                <p>
                                  <strong>Bot </strong>Los bot de charla o bot
                                  conversacional, ​ son aplicaciones software
                                  que surgen en los años 60, y que simulan
                                  mantener una conversación con una persona al
                                  proveer respuestas automáticas, las cuales son
                                  previamente establecidas por un conjunto de
                                  expertos a entradas realizadas por el usuario.
                                  <a
                                    href=" https://secure.payulatam.com/online_account/create_account.zul"
                                    target="_blank"
                                  >
                                    aquí
                                  </a>
                                  .{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <footer>
                        <div className="row">
                          <div className="col-md-12 right">
                            {viewOrEdit && (
                              <input
                                type="button"
                                // type="submit"
                                className="btn btn-default mr-2"
                                value="Ver/Editar Bot"
                                onClick={() => {
                                  setMode("create");
                                }}
                              />
                            )}
                            <button type="submit" className="btn btn-info">
                              Guardar
                            </button>
                          </div>
                        </div>
                      </footer>
                    </form>
                  </div>
                </div>
              ) : null}
            </section>
          </div>
        </div>
      ) : (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                onClick={() => {
                  setMode("list");
                }}
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute"
              >
                <FontAwesomeIcon icon={faChevronLeft} /> ChatBot
              </button>
            </div>
            <Header />
          </div>

          <section
            className="module-create-user mb-1"
            style={{ width: "100%" }}
          >
            <header className="message d-flex justify-content-between py-3">
              <div id="tittleFormProduct">
                <h1>{botName}</h1>
                <p className="m-0">{descriptionBot}</p>
              </div>
            </header>
            <form className="Form-create-new-list">
              <div className="form-create-bot mb-3 px-3">
                <ReactFlow
                  nodes={nodes}
                  edges={edgesWithUpdatedTypes}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onInit={onInit}
                  fitView
                  style={{ width: "100%", height: "70vh" }}
                  onConnect={onConnect}
                  connectionLineStyle={{ stroke: "#ddd", strokeWidth: 2 }}
                  connectionLineType="bezier"
                  snapToGrid={true}
                  snapGrid={[16, 16]}
                  onNodeClick={onClickNode}
                  onEdgeClick={onClickEdge}
                  onEdgeUpdate={onEdgeUpdate}
                  onEdgeUpdateStart={onEdgeUpdateStart}
                  onEdgeUpdateEnd={onEdgeUpdateEnd}
                  // onEdgesDelete={(t) => yourFunction2(t)}
                >
                  <Background color="#aaa" gap={16} />
                  <MiniMap
                    nodeColor={(n) => {
                      if (n.type === "input") return "blue";

                      return "#FFCC00";
                    }}
                  />
                  <Controls />
                </ReactFlow>
              </div>
            </form>
          </section>

          <section className="module-create-user" style={{ width: "100%" }}>
            <form className="Form-create-new-list text-center py-1">
              <div className="form-create-bot my-3 px-3 d-flex justify-content-between">
                <div>
                  <a
                    href="javascript:;"
                    className="btn btn-lg btn-default mr-1"
                    onClick={() => {
                      setMode("list");
                    }}
                  >
                    Volver
                  </a>
                </div>
                <div>
                  <a
                    href="javascript:;"
                    className="btn btn-lg btn-default mr-1"
                    onClick={() => {
                      setCreateOrEdit("Crear");
                      setModalCreateAnswer(true);
                      setSelectedNode("");
                      setInputStr("");
                      // setCreateResponse(true);
                    }}
                  >
                    Crear respuesta
                  </a>
                  <input
                    className="btn btn-lg btn-info"
                    value="Guardar"
                    name="submit"
                    id="submit"
                    // type="submit"
                    type="button"
                    onClick={() => onSaveBot()}
                  />
                </div>
              </div>
            </form>
          </section>
        </>
      )}

      <Modal
        status={modalCreateAnswer}
        changeStatus={setModalCreateAnswer}
        title={`${createOrEdit} respuesta`}
        action={() => null}
        width={"660px"}
      >
        <Content>
          <div className="justify-content-center">
            <form onSubmit={handleSubmit(onAddNode)}>
              <div className="justify-content-center">
                <div className="row mb-3">
                  {selectedNode != 1 && (
                    <>
                      <div className="col-md-12">
                        <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                          <label htmlFor="name">Respuesta del usuario: </label>
                          <div className="su-control-group">
                            <input
                              className="form-control"
                              name="userResponse"
                              id="userResponse"
                              type="text"
                              value={userResponse}
                              onChange={(e) => {
                                setUserResponse(e.target.value);
                              }}
                            />
                          </div>
                          {userResponseNot && (
                            <Alert>
                              * La respuesta del usuario es requerida
                            </Alert>
                          )}
                          <span style={{ fontSize: "11px", color: "#a3a3a3" }}>
                            Este será el mensaje que debe enviar el usuario para
                            poder activar esta respuesta.
                          </span>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="su-control su-switch mt-2">
                          <div className="switch">
                            <input
                              id="returnMenu"
                              className="cmn-toggle cmn-toggle-round-flat"
                              type="checkbox"
                              name="returnMenu"
                              checked={returnMenu}
                              // value={returnMenu}
                              onChange={(e) => setReturnMenu(e.target.checked)}
                            />
                            <label htmlFor="returnMenu"></label>
                          </div>
                          <label
                            className="form-check-label f-15 f-w-400 ml-3"
                            htmlFor="returnMenu"
                          >
                            Regresar menú anterior
                          </label>
                        </div>
                        <span style={{ fontSize: "11px", color: "#a3a3a3" }}>
                          Marca esta opción para hacer que el usuario vuelva a
                          la respuesta anterior.
                        </span>
                      </div>
                    </>
                  )}

                  <div className="col-md-12">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="email">Respuesta del Bot: </label>
                      <div className="su-control-group">
                        <textarea
                          name="content"
                          className="form-control"
                          spellCheck="true"
                          value={inputStr}
                          onChange={(e) => {
                            setNameElement(e.target.value);
                            setInputStr(e.target.value);
                          }}
                        ></textarea>
                        <i
                          className={
                            showPicker
                              ? "icon-cancel ml-1 mr-2 d-flex pointer"
                              : "icon-smile icon-chat d-flex"
                          }
                          style={
                            showPicker
                              ? { fontSize: "20px", alignItems: "center" }
                              : { fontSize: "22px", alignItems: "center" }
                          }
                          onClick={() => {
                            setShowPicker((val) => !val);
                          }}
                        />
                        {showPicker && (
                          <Picker
                            pickerStyle={{
                              width: "100%",
                              position: "absolute",
                              zIndex: "999",
                              bottom: "50px",
                            }}
                            onEmojiClick={onEmojiClick}
                            groupNames={{
                              smileys_people: "Emoticones y personas",
                              animals_nature: "Animales y naturaleza",
                              food_drink: "Alimentos y bebidas",
                              travel_places: "Viajes y lugares",
                              activities: "Actividades",
                              objects: "Objetos",
                              symbols: "Símbolos",
                              flags: "Banderas",
                              recently_used: "Recientes",
                            }}
                          />
                        )}
                      </div>
                      {inputStrNot && (
                        <Alert>* La respuesta del Bot es requerida</Alert>
                      )}
                      <span style={{ fontSize: "11px", color: "#a3a3a3" }}>
                        Esta será la respuesta que se enviará al usuario. Puedes
                        incluir emojis
                      </span>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="email">
                        Mensaje a respuesta no encontrada:{" "}
                      </label>
                      <div className="su-control-group">
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          type="text"
                          placeholder="Enviar archivo(s)"
                        />
                      </div>
                      <span style={{ fontSize: "11px", color: "#a3a3a3" }}>
                        {`(Opcional) Si quieres enviar uno o varios archivos, solo debes adjuntarlos aqui`}
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="row justify-content-end">
                <a
                  href="javascript:;"
                  className="btn btn-lg btn-default mr-1"
                  onClick={() => setModalCreateAnswer(false)}
                >
                  Cancelar
                </a>
                <input
                  className="btn btn-lg btn-info"
                  value="Guardar"
                  name="submit"
                  id="submit"
                  type="button"
                  onClick={createOrEdit === "Crear" ? onAddNode : onEditNode}
                />
              </div>
            </form>
          </div>
        </Content>
      </Modal>

      <Modal
        status={action}
        changeStatus={setAction}
        title="Ación a realizar"
        action={() => null}
        width={"445px"}
      >
        <Content>
          {/* <div className="justify-content-center">
            <form> */}
          <div className="d-flex justify-content-evenly py-4 w-100">
            {/* <div className="row mb-3"> */}
            {selectedNode != 1 && (
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setAction(false);
                  onDeleteNode();
                }}
              >
                <i className="icon-trash-empty" style={{ fontSize: "18px" }} />
                Eliminar
              </a>
            )}
            <a
              href="javascript:;"
              className="btn btn-lg btn-info"
              onClick={() => {
                setModalCreateAnswer(true);
                setAction(false);
              }}
            >
              <i className="icon-pencil" style={{ fontSize: "18px" }} />
              Editar
            </a>
            {/* </div> */}
          </div>

          <div className="row justify-content-center mt-2">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => setAction(false)}
            >
              Cancelar
            </a>
          </div>
          {/* </form>
          </div> */}
        </Content>
      </Modal>
    </Fragment>
  );
};

export default ChatBot;

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    vertical-align: top;
    border-radius: 3px;
  }

  // label {
  //   font-weight: 900;
  //   font-size: 18px;
  // }

  input {
    padding: 1px 5px;
    border-radius: 0px;
    font-size: 17px;
  }

  h5 button {
    color: #5bc0de;
  }

  h5 button:hover {
    color: #28a1c5;
  }
`;
