import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import styled from "styled-components";
import Swal from "sweetalert2";
import Modal from "../layout/Modal";
import Spinner from "../plugins/Spinner";
import { useForm } from "react-hook-form";
import Pagination from "../plugins/Pagination";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import {
  faMagnifyingGlass,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../layout/Header";
import TemplateWhatsapp from "./TemplateWhatsapp";
import { BsSearch, BsPencilFill, BsTrash3, BsPlusSquareDotted, BsDashSquareDotted, BsWhatsapp, BsBoxes, BsBoxSeam } from "react-icons/bs";
import { BiListPlus } from "react-icons/bi";
import { FaEye, FaListUl, FaAngleLeft } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { AiFillWarning } from "react-icons/ai";
import { TbTemplate } from "react-icons/tb";

const Catalog = () => {
  //# Estados del Modulo (Modales, Spinner, etc)
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const [validationSets, setValidationSets] = useState(false);
  
  //# Estados para los servicios
  const [catalogs, setCatalogs] = useState([]);
  const [tableProducts, setTableProducts] = useState([]);
  const [search, setSearch] = useState("");
  
  const [products, setProducts] = useState([]);
  const [sets, setSets] = useState([]);
  const [tableSets, setTableSets] = useState([]);
  
  const [modalViewProduct, setModalViewProduct] = useState(false);
  const [productSelected, setProductSelected] = useState([]);

  const [isProducts, setIsProducts] = useState(true);
  const [filterList, setFilterList] = useState("products");

  const [productsOfSet, setProductsOfSet] = useState([]);
  const [modalViewSet, setModalViewSet] = useState(false);
  const [setNameSelected, setSetNameSelected] = useState("");

  const { page, setPage, byPage, resetPage } = usePage();
  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  const navigate = useNavigate();

  //# React-hook-form para establecer validaciones
  const {watch, register, handleSubmit, formState: { errors }, reset,} = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      type: "",
      content: ""
    },
  });

  useEffect(() => {
    getSets();
    getProducts();
  }, []);

  //#--> Sincronizar catálogo
  const getCatalog = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "catalog";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("LISTA catalogoooooosssss ==========>>", result);
      if (result.type == "info" || result.type == "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
        setLoading(false);
      } else {
        await getProducts()
        await getSets()
        setLoading(false);
      }

      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });

    } catch (error) {
      console.log(error);
    }
  };

  //#--> Listar productos
  const getProducts = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "products";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("LISTA productos ==========>>", result);
      setLoading(false);
      if (result.type === "error") {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (result.data.length === 0) {
        setValidation(true);
        setProducts([]);
        setTableProducts([]);
      } else {
        setProducts(result.data);
        setTableProducts(result.data);
        setValidation(false);

      }
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Listar sets
  const getSets = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "sets";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await res.json();
      console.log("LISTA sets ==========>>", result);
      setLoading(false);
      if (result.data.length === 0) {
        setValidationSets(true);
        setSets([]);
        setTableSets([]);
      } else {
        setSets(result.data);
        setTableSets(result.data);
        setValidationSets(false);

      }
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Buscador de productos
  const searchProducts = (searchTerm) => {
    var searchResults = tableProducts.filter((element) => {    
      if (
        element.name.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        element.description.toString().toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return element;
      }
      
    });
    setProducts(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };
  //#--> Buscador de conjuntos
  const searchSets = (searchTerm) => {
    var searchResults = tableSets.filter((element) => {    
      if (element.name.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return element;
      }
      
    });
    setSets(searchResults);
    if (searchResults.length === 0) {
      setValidationSets(true);
    } else {
      setValidationSets(false);
    }
  };

  //#--> ver detalles del producto seleccionado
  const onViewProduct = async (id) => {
    setModalViewProduct(true)

    const filtProduct = products.filter(pro => pro.id === id)[0];
    setProductSelected(filtProduct)
  }

  //#--> ver productos del Set seleccionado
  const onViewSet = async (id) => {
    setModalViewSet(true)

    const filtSet = sets.filter(set => set.id === id)[0];
    setSetNameSelected(filtSet.name)
    let listProducts = [];
    if (filtSet.name == "Todos los productos") {
      listProducts = products
    } else {
      for (let pro of filtSet.products) {
        const resProd = products.find(p => p.retailer_id == pro.id)
        listProducts.push(resProd)
      }
    }
    setProductsOfSet(listProducts)
  }


  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      
      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div className="mt-2">
            <h1>Catálogo</h1>
            <input
              type="button"
              className="btn btn-default"
              value="Sincronizar"
              onClick={() => getCatalog()}
            />
          </div>

          <Header />
        </div>

        <header className="d-flex justify-content-between w-100 align-items-end">
          <ul className="list-filter-list d-flex align-items-baseline">
            <li>
              <label 
                className={`pointer ${filterList === "products" && "active"} d-flex align-items-center`} 
                onClick={() => {
                  setFilterList("products")
                  setSearch("")
                  setProducts(tableProducts)
                  setValidationSets(false)
                }}>
                <BsBoxSeam className="mr-1"/> Productos
              </label>
            </li>
            <li className="ml-3">
              <label 
                className={`pointer ${filterList === "sets" && "active"} d-flex align-items-center`} 
                onClick={() => {
                  setFilterList("sets")
                  setSearch("")
                  setSets(tableSets)
                  setValidation(false)
                }}>
                <BsBoxes className="mr-1"/> Conjuntos
              </label>
            </li>
          </ul>

          <div>
            <form className="form-inline">
              <div className="su-control my-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  value={search}
                  onChange={(e) => {
                    if (filterList === "products") {
                      searchProducts(e.target.value);
                    } else {
                      searchSets(e.target.value)
                    }
                    setSearch(e.target.value);
                    resetPage();
                  }}
                />
                <button
                  className="btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </form>
          </div>
        </header>

        <div className="container-fluid mt-0 px-0" style={{ height: "75%", overflowY: "auto", overflowX: "none" }}>
          <table className="table table-striped">
            <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
              {filterList === "products" ? 
                <tr>
                  <th>Acciones</th>
                  <th></th>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Precio</th>
                  <th>Precio de oferta</th>
                </tr>
              :
                <tr>
                  <th>Acciones</th>
                  <th>Nombre</th>
                  <th>Cantidad de productos</th>
                </tr>
              }
            </thead>
            <tbody>
              {(filterList === "products" ? products : sets).slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((item, k) => {                 
                return(
                <tr key={k}>
                  <td className="d-flex justify-content-center">
                    <button
                      className="text-primary border-0 bg-transparent d-block pointer mt-2"
                      onClick={() => {
                        if(filterList === "sets") {
                          onViewSet(item.id)
                        } else {
                          onViewProduct(item.id)
                        }
                      }}
                    >
                      <FaEye style={{fontSize: "18px",color: "#ff9317",}} />
                    </button>
                  </td>
                  {filterList === "products" && <td className="p-0"><img src={ item.image_url } style={{height: "100%"}} /></td>}
                  <td>{item.name.length >= 35 ? item.name.substr(0, 35) + "..." : item.name}</td> 
                  {filterList === "sets" && <td>{item.products.length === 0 ? "Todos" : item.products.length }</td> }
                  {filterList === "products" && <><td>{item.description.length >= 35 ? item.description.substr(0, 35) + "..." : item.description}</td>
                  <td style={{textDecoration: item.sale_price && "line-through" }}>{item.price}</td>
                  <td>{item.sale_price ? item.sale_price : 0}</td></>}
                </tr>
              )})}
            </tbody>
          </table>
          {filterList === "products" &&
            validation && (
              <div className="text-center mt-3">
                <div className="empty">
                  <BsBoxSeam style={{ fontSize: "55px" }} />
                  <h1>No tienes Productos</h1>
                  <input
                    type="button"
                    className="btn btn-default"
                    value="Sincronizar"
                    onClick={() => getCatalog()}
                  />
                </div>
              </div>
            )
          }

          {filterList === "sets" && 
            validationSets && (
              <div className="text-center mt-3">
                <div className="empty">
                  <BsBoxes style={{ fontSize: "55px" }} />
                  <h1>No tienes conjuntos</h1>
                  <input
                    type="button"
                    className="btn btn-default"
                    value="Sincronizar"
                    onClick={() => getCatalog()}
                  />
                </div>
              </div>
            )
          }
        </div>

        <footer>
          <div className="row">
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <Pagination
                totalData={filterList === "products" ? products.length : sets.length}
                byPage={byPage}
                setPage={setPage}
                />
            </div>
          </div>
        </footer>
      </div>


      {/* Modal para ver los detalles del producto seleccionado */}
      <Modal
        status={modalViewProduct}
        changeStatus={setModalViewProduct}
        title="Información del producto"
        width={"90vw"}
      >
        <Content>
          <form style={{ width: "100%" }}>
            <div>
              <div 
                className="mt-2 text-center d-flex justify-content-between"
                style={{overflow: "auto", height: "76vh"}}    
              >
                <div className="w-50 mr-1">
                  <img src={productSelected.image_url} className="w-100 h-100" style={{objectFit:"contain"}}/>
                </div>

                <div className="w-50 ml-1 px-2 text-justify">
                  <h2>{productSelected.name}</h2>
                  <h4 className="d-flex align-items-baseline">{productSelected.sale_price ? 
                    <>
                      <h5>
                        <span style={{color: "#a5a7a8", textDecoration: "line-through" }}>{productSelected.price}</span>
                      </h5> 
                      <span className="ml-1">
                        {productSelected.sale_price} 
                      </span> 
                    </>
                  : 
                    productSelected.price}
                  </h4>
                  <p>{productSelected.description}</p>

                  <div className="text-center mt-3">
                    <a 
                      href={productSelected.url} 
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-lg btn-default mr-1" 
                    >
                      Visitar página web
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row justify-content-end bd col-12 mt-4">
              <a 
                href="javascript:;" 
                className="btn btn-lg btn-default mr-1" 
                onClick={() => {
                  setModalViewProduct(false);
                }}
              >
                Cerrar
              </a>
            </div> */}
          </form>


        </Content>
      </Modal>

      {/* Modal para ver productos del set seleccionado */}
      <Modal
        status={modalViewSet}
        changeStatus={setModalViewSet}
        title={`Productos de ${setNameSelected}`}
        width={"500px"}
      >
        <Content>
          <form style={{ width: "100%" }}>
            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
            
                <ul className="content-list-contacts mt-3" style={{height:"350px", overflow:"auto"}}>
                  {productsOfSet && productsOfSet.map(pro => (
                    <li key={pro._id} className="d-flex justify-content-between">
                      <div className="text-center col-3">
                        <img src={pro.image_url} style={{height:"3rem"}}/>
                      </div>

                      <div className="col-6">
                        <h5>
                          {pro.name}
                        </h5>

                        {pro.sale_price ? 
                          <div className="d-flex">
                            <span>
                              <span style={{textDecoration: "line-through" }}>{pro.price}</span>
                            </span> 
                            <span className="span-color ml-1" >
                              {pro.sale_price} 
                            </span> 
                          </div>
                        : 
                        <div className="span-color" >
                          {pro.price}
                        </div>
                        }
                      </div>
                    </li>
                  ))}
                </ul>

            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a 
                href="javascript:;" 
                className="btn btn-lg btn-default mr-1" 
                onClick={() => {
                  setModalViewSet(false);
                  // setSelectProductsSend(false);
                  // formSendCatalog.reset();
                }}
              >
                Cancelar
              </a>
              {/* <input
                className="btn btn-lg btn-info"
                type="submit"
                value="Continuar"
              /> */}
            </div>
          </form>
        </Content>
      </Modal>

    </Fragment>
  );
};

export default Catalog;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 85px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  // .content-img .icon-eye {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .content-img img:hover {
    opacity: 0.6;
  }
`;
