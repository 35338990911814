import Swal from "sweetalert2";
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";

const useAPI = () => {
  const navigate = useNavigate();
  const urlBase = "https://api.sendingup.co/";
  const urlAPI = urlBase; //# Producción

  // API_1
  //const urlAPI_1 = urlBase + "prod/api1/" //"https://106ul0a45f.execute-api.us-east-1.amazonaws.com/prod/"//urlBase + "api_a/"; //# Producción API_1
  const urlAPI_1 = "https://tvd6p8m4xk.execute-api.us-east-1.amazonaws.com/dev/"//urlBase + "api_a/"; //# Producción API_1
  //const urlAPI_1 = "http://localhost:3000/dev/"; // pruebas

  // API_2
  //const urlAPI_2 = urlBase + "prod/api2/" //"https://wlea75oglc.execute-api.us-east-1.amazonaws.com/prod/" //urlBase + "api_b/"; //# Producción API_2
  const urlAPI_2 = "https://h671xnrd4j.execute-api.us-east-1.amazonaws.com/dev/" //urlBase + "api_b/"; //# Producción API_2
  //const urlAPI_2 = "http://localhost:3001/dev/"; // pruebas

  const urlSocket = "wss://ffmqisvqwh.execute-api.us-east-1.amazonaws.com/dev/";
  //const urlSocket = "wss://b01jbyxtga.execute-api.us-east-1.amazonaws.com/production/";

  const daysOverdue = 8;
  const name_version_app = "Qasar";
  const number_version_app = "3.0";

  const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem("USER_TOKEN");
    if(!token){
      console.log("NO EXISTE TOKEN!!!");
      Swal.fire({
        title: "¡Sesión Expirada!",
        text: "Tu sesión ha expirado. Por favor, inicia sesión de nuevo.",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      // Redirijo al login
      navigate("/");
    }

    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  
    try {
      const response = await fetch(url, {
        ...options,
        headers,
      });
  
      // Verifico si el token ha expirado o no está presente
      if (response.status != 200 && !localStorage.getItem("USER_TOKEN")) {
        Swal.fire({
          title: "¡Sesión Expirada!",
          text: "Tu sesión ha expirado. Por favor, inicia sesión de nuevo.",
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        // Redirijo al login
        navigate("/");
      }
  
      // Si no hubo error, devuelvo la respuesta
      return response;
    } catch (error) {
      console.error("Error en la API:", error);
      throw error;
    }
  };
  
  return {
    urlAPI,
    urlAPI_1,
    urlAPI_2,
    daysOverdue,
    urlSocket,
    name_version_app,
    number_version_app,
    fetchWithAuth
  };
};

export default useAPI;
