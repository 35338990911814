import React from 'react';
import { BsPlusSquareDotted, BsTrash3, BsPencilFill, BsFillCameraVideoFill, BsFillFileEarmarkTextFill } from "react-icons/bs";
import { FaHeadphones, FaPlus, FaImages } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { HiTemplate } from "react-icons/hi";
import { LuWorkflow } from "react-icons/lu";
import { TbCubeSend } from "react-icons/tb";
import { FcAlarmClock } from "react-icons/fc";

const ShippingOptions = ({
  viewAnswers,
  quicklyAnswers,
  formSendFlow,
  formSendFlow2,
  formSendCatalog,
  formSendCatalog2,
  formGenerateLink,
  setViewAnswers,
  setStartChat,
  setModalTemplateSend,
  setIsChatSelected,
  setNotChatSelected,
  setIsTemplateSend,
  setContent,
  setIsUrlDynamic,
  setIsUrlDynamic2,
  setIdTemplate,
  setNumberParams,
  setTemplateSelected,
  setListButtonsUrlDynamic,
  setModalFlowSelected,
  setHeaderSelected,
  setTextHeaderFlow,
  setMedioSelected,
  setTextBodyFlow,
  setTextFooterFlow,
  setTextBottonFlow,
  setSenFlowOrProducts,
  setModalSendProducts,
  setSelectProductsSend,
  setTextHeaderCatalog,
  setSelectedTypeProducts,
  setModalGenerateLink,
  setEditAnswer,
  setCreateAnswer,
  setSelectedAnswerId,
  reset,
  setInputStr2,
  setAnswerAgent,
  setAnswerArea,
  setSelectedRadio,
  setIsImgAnswer,
  setImgAnswer,
  setTypeFileAnswer,
  setMediaNameAnswer,
  setAnswerAgentError,
  setAnswerAreaError,
  setAnswerTitleError,
  setInputStr2Error,
  setAnswerTitle,
  setViewSelectedImage,
  setTemplateWhatsapp,
  onFileInput,
  onSendMessage,
  getIsDark,
  deleteQuickReply,
  setScheduleShipping
}) => {
  return (
    <div className="dropdown">
      <button
        className="bg-transparent border-0 d-flex"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FaPlus
          className="icon-chat icon-plus"
          style={{ fontSize: "28px", color: "#455a64", zIndex: "999" }}
          aria-hidden="true"
          title="Adjuntar"
        />
      </button>

      <div className="dropdown-menu py-3 px-1" aria-labelledby="dropdownMenuButton" style={{ width: "230px" }}>
        <ul>
          <li className="options-drop">
            <input
              type="file"
              name="image"
              id="images"
              accept="image/gif,image/jpeg,image/jpg,image/png"
              style={{ display: "none" }}
              onChange={(e) => onFileInput(e, "image")}
              multiple
            />
            <label htmlFor="images" title="Imagenes" className="pointer w-100 d-flex">
              <FaImages className="mr-2 icon-picture" style={{ fontSize: "20px", color: "#007dfc" }}/>
              Imagenes
            </label>
          </li>

          <li className="options-drop">
            <input
              type="file"
              name="videos"
              id="videos"
              accept="video/*"
              style={{ display: "none" }}
              onChange={(e) => onFileInput(e, "video")}
              multiple
            />
            <label htmlFor="videos" title="Videos" className="pointer w-100 d-flex">
              <BsFillCameraVideoFill className="mr-2 icon-file-video" style={{ fontSize: "20px", color: "#ff0068" }}/>
              Videos
            </label>
          </li>

          <li className="options-drop">
            <input
              type="file"
              name="documents"
              id="documents"
              accept=".pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: "none" }}
              onChange={(e) => onFileInput(e, "document")}
              multiple
            />
            <label htmlFor="documents" title="Documentos" className="pointer w-100 d-flex">
              <BsFillFileEarmarkTextFill className="mr-2 icon-doc" style={{ fontSize: "22px", color: "#8165fd" }}/>
              Documentos
            </label>
          </li>

          <li className="options-drop">
            <input
              type="file"
              name="image"
              id="audioss"
              accept="audio/*"
              style={{ display: "none" }}
              onChange={(e) => onFileInput(e, "audio")}
              multiple
            />
            <label htmlFor="audioss" title="Audios" className="pointer w-100 d-flex">
              <FaHeadphones className="mr-2 icon-headphones" style={{ fontSize: "20px", color: "#ffb92f" }}/>
              Audios
            </label>
          </li>

          <li className="options-drop d-flex" onClick={() => {setViewAnswers(true)}}>
            <RiQuestionAnswerFill className="mr-2 icon-headphones pointer" style={{ fontSize: "20px", color: "#00a0e3" }}/>
            <label className="pointer">Respuestas rápidas</label>
          </li>

          <li
            className="options-drop d-flex pointer"
            onClick={() => {
              getIsDark()
              setStartChat(true);
              setModalTemplateSend(false);
              setIsChatSelected(false);
              setNotChatSelected(false);
              setIsTemplateSend(true);
              setContent("");
              setIsUrlDynamic("");
              setIsUrlDynamic2("");
              setIdTemplate("");
              setNumberParams([]);
              setTemplateSelected([]);
              setListButtonsUrlDynamic([]);
              setTemplateWhatsapp(false);
            }}
          >
            <HiTemplate className="mr-2 tbTemplate pointer" style={{ fontSize: "20px", color: "#00a99b" }}/>
            <label className="pointer">Enviar plantilla</label>
          </li>

          <li
            className="options-drop d-flex pointer"
            onClick={() => {
              setModalFlowSelected(true);
              formSendFlow.reset()
              formSendFlow2.reset()
              setHeaderSelected("")
              setTextHeaderFlow("")
              setMedioSelected("")
              setTextBodyFlow("")
              setTextFooterFlow("")
              setTextBottonFlow("")
              setSenFlowOrProducts(1)
            }}
          >
            <LuWorkflow className="mr-2 tbTemplate pointer" style={{ fontSize: "21px", color: "#d35900" }}/>
            <label className="pointer">Enviar flujo</label>
          </li>

          <li
            className="options-drop d-flex pointer"
            onClick={() => {
              getIsDark()
              setStartChat(true);
              setModalTemplateSend(false);
              setIsChatSelected(false);
              setNotChatSelected(false);
              setIsTemplateSend(true);
              setContent("");
              setIsUrlDynamic("");
              setIsUrlDynamic2("");
              setIdTemplate("");
              setNumberParams([]);
              setTemplateSelected([]);
              setListButtonsUrlDynamic([]);
              setTemplateWhatsapp(false);
              setScheduleShipping(true);
            }}
          >
            <FcAlarmClock className="mr-2 tbTemplate pointer" style={{ fontSize: "20px", color: "#00a99b" }}/>
            <label className="pointer">Programar envío</label>
          </li>

          <li
            className="options-drop d-flex pointer"
            onClick={() => {
              setModalSendProducts(true)
              setSelectProductsSend(true)
              setSenFlowOrProducts(2)
              formSendCatalog.reset()
              formSendCatalog2.reset()
              setTextHeaderCatalog("")
              setTextBodyFlow("")
              setTextFooterFlow("")
              setSelectedTypeProducts("1")
            }}
          >
            <TbCubeSend className="pointer mr-2" style={{ fontSize: "21px", color: "#0084ff" }}/>
            <label className="pointer">Enviar productos</label>
          </li>

          <li className="options-drop d-flex pointer" onClick={() => {setModalGenerateLink(true); formGenerateLink.reset({totalOrder: ""})}}>
            <IoIosLink className="pointer mr-2" style={{ fontSize: "21px", color: "#5cd146" }}/>
            <label className="pointer">Enviar link de cobro</label>
          </li>
        </ul>
      </div>

      {viewAnswers && (
        <div className="content-answers p-0" style={{ position: "absolute", bottom: "2.5rem", backgroundColor: "#fff", zIndex: "9999" }}>
          <header className="pl-3 d-flex justify-content-between" style={{borderTopLeftRadius: "12px", borderTopRightRadius: "12px"}}>
            <h4 className="m-0">Respuestas rápidas</h4>
            <IoClose className="pointer" style={{fontSize: "1.4rem"}} onClick={() => setViewAnswers(false)}/>
          </header>

          <div className="px-3 list-quickly-answers" style={{ height: "176px", overflow: "auto" }}>
            {quicklyAnswers.length <= 0 ?
              <div className="text-center mt-3">
                <h3 className="text-center mt-3">Sin respuestas</h3>
              </div>
            :
              <ul>
                {quicklyAnswers.map((quickly, q) => (
                  <li key={q} id="quicklyAnswers" className="pointer py-2 px-1"
                    style={{
                      borderBottom:"1px solid rgb(0,0,0,.05)",
                      display: "flex",
                      fontSize: "0.9em",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{display: "grid"}} onClick={() => {onSendMessage(quickly._id);}}>
                      <strong>{quickly.name}</strong>
                      {quickly.content.length >= 46 ? quickly.content.substr(0, 46) + " ..." : quickly.content}
                    </div>
                    <div className="icons-actions">
                      <BsPencilFill
                        className="icon-pencil mr-1"
                        title="Editar"
                        onClick={() => {
                          setViewAnswers(false)
                          setEditAnswer(true);
                          setCreateAnswer(true);
                          setSelectedAnswerId(quickly._id);
                          reset({answerName:quickly.name, answerContent:quickly.content, answerAgent:quickly.user, answerArea:quickly.area });
                          setInputStr2(quickly.content);
                          setAnswerAgent(quickly.user);
                          setAnswerArea(quickly.area);
                          setSelectedRadio(quickly.answerSettings)
                          // console.log("📝📝📝📝📝📝📝📝📝📝📝📝📝📝📝::", quickly)
                          if (quickly.media) {
                            // console.log("✅✅✅✅✅✅✅MEDIOOOOO✅✅✅✅✅✅✅✅")
                            setIsImgAnswer(true);
                            setImgAnswer(quickly.media);
                            setTypeFileAnswer(quickly.mediaType);
                            setMediaNameAnswer(quickly.mediaName);
                            setViewSelectedImage(quickly.media);
                          } else {
                            // console.log("❌❌❌❌❌❌❌ NO HAY MEDIO ❌❌❌❌❌❌❌❌")
                            setIsImgAnswer(false);
                            setImgAnswer("");
                            setTypeFileAnswer("");
                            setMediaNameAnswer("");
                          }
                        }}
                        style={{ fontSize: "18px" }}
                      />
                      <BsTrash3
                        className="icon-trash-empty ml-1"
                        title="Eliminar"
                        onClick={() => deleteQuickReply(quickly._id)}
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            }
          </div>

          <div className="d-flex justify-content-end py-2">
            <button
              type="button"
              className="border-0 bg-transparent d-block pointer btn-personalize d-flex align-items-center"
              onClick={() => {
                reset({answerName: "",answerContent: ""});
                setCreateAnswer(true)
                setInputStr2("");
                setAnswerTitle("");
                setEditAnswer(false);
                setIsImgAnswer(false);
                setImgAnswer("");
                setAnswerAgent("");
                setAnswerArea("");
                setAnswerAgentError(false);
                setAnswerAreaError(false);
                setAnswerTitleError(false);
                setInputStr2Error(false);
                setSelectedRadio("1")
              }}
            >
              <BsPlusSquareDotted style={{ fontSize: "16px" }}/>
              <span className="ml-1 f-14">
                <strong>Crear respuesta</strong>
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShippingOptions;