import { useQuery, useInfiniteQuery} from '@tanstack/react-query';
import { queryClient } from "../plugins/queryClient";

import Avvvatars from "avvvatars-react";
import React, { Fragment, useContext, useEffect, useState, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import useAPI from "../../hooks/useAPI";
import Spinner from "../plugins/Spinner";
import Modal from "../layout/Modal";
import Select from "react-select";
import Header from "../layout/Header";
import { BsSearch, BsTrash3} from "react-icons/bs";
import { FaAngleLeft } from "react-icons/fa";
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import debounce from 'lodash/debounce';


const List = () => {
  const [list, setList] = useState({name: "", description: "", persons: ""});
  const [validation, setValidation] = useState({});
  const [personsList, setPersonsList] = useState([]);
  const [personsListFilter, setPersonsListFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(500);
  const [title, setTitle] = useState('');
  const [modalFilter, setModalFilter] = useState(false);
  const [listTags, setListTags] = useState([]);
  const [correspondence, setCorrespondence] = useState([]);
  const [selectedTags, setSelectedTags] = useState({_id: "123abc123" , title: "Todos"});
  const [isDark, setIsDark] = useState(false);
  const [selectedSort, setSelectedSort] = useState({id: "1", title: "Fecha de Creación"});
  const [segments, setSegments] = useState([]);
  const [segmentsValidate, setSegmentsValidate] = useState(false);
  const [listPeopleAdded, setListPeopleAdded] = useState([]);

  const page = useRef(0);
  const totalPages = useRef(1);
  const searchTermRef = useRef("");
  const listContactsRef = useRef([])
  const personsCheckedList = useRef({})
  const personsAdd = useRef({})
  const personsChecked = useRef(new Set())

  const { id_param } = useParams();
  const token = localStorage.getItem('USER_TOKEN');
  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();
  
  const { watch, register, handleSubmit, formState: { errors, isDirty }, reset, } = useForm({ mode: "all", defaultValues: { name: "", description: "", persons: {} } });

  const formFilter = useForm();
  const error2 = formFilter.formState.errors;
  // const formFilter = useForm();
  // const error3 = formFilter.formState.errors;

  const listOfSortOptions = [
    {id: "1", title: "Fecha de Creación"},
    {id: "2", title: "Orden alfabetico"}
  ]

  const navigate = useNavigate();

  useEffect(() => {
    console.log("id_param: ", id_param);
    if(id_param == "new"){
      setTitle((prev) => { return "Nueva lista" });
      const name = "";
      const description = "";
      reset({ name, description });
    }else{
      setTitle((prev) => { return "Editando: " });
      getList();
    }
    fetchPersons();
    getTags();
    getCorresp();
  }, []);


  const fetchPersons = async (reset = false, filter = false) => {
    try {
      setLoading(true);
      let url;
      if (reset) {
        url = urlAPI_1 + `personsByList/${id_param}?page=0&pageSize=${pageSize}&search=`;
      } else if (filter) {
        console.log('entro acá porque es filtroooooooooo.........................🤫')
        url = urlAPI_1 + `personsByList/${id_param}?page=0&pageSize=${pageSize}&filter=` + filter;
        page.current = 0
      } else{
        url = urlAPI_1 + `personsByList/${id_param}?page=${page.current}&pageSize=${pageSize}&search=${searchTermRef.current}`;
      }
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log('Lista de persons      👩‍🦱👩‍🦱 : ', result.data)
      if (result.code === 0) {
        Swal.fire({
          title: '¡Atención!',
          text: result.message,
          icon: result.type,
        });
      } else {
        let listPersonsCheck;
        let listPersonsCheck_aux = result.data.receiversList || [];
        listPersonsCheck_aux = listPersonsCheck_aux.map((row) => {
          if(personsChecked.current.size){
            if(personsChecked.current.has(row._id)){
              row.userActive = true
            }else{
              row.userActive = false
            }
          }
          else{
            row.userActive = parseBoolean(row.userActive);
          }
          return row;
        });

        console.log("listPersonsCheck_aux: ", listPersonsCheck_aux);

        if(searchTermRef.current || page.current == 0){
          listPersonsCheck = listPersonsCheck_aux;
        }else{
          listPersonsCheck = [...listContactsRef.current, ...listPersonsCheck_aux];
        }

        const clientsTrue = listPersonsCheck.filter((cliente) => cliente.userActive === true);
        clientsTrue.forEach((receiver) => {
          personsChecked.current.add(receiver._id);
          personsCheckedList.current[receiver._id] = receiver;
        })

        if (page.current == 0) {
          const clientsFalse = listPersonsCheck.filter((cliente) => cliente.userActive === false);
          const orderedClients = [...clientsTrue, ...clientsFalse];
          listContactsRef.current = orderedClients;
          setPersonsList((prev) => { return orderedClients });
          setPersonsListFilter((prev) => { return orderedClients });
        } else {
          let new_clients_true = []; 
          for(let key_id in personsCheckedList.current){
            new_clients_true.push(personsCheckedList.current[key_id]);
          }

          const clientsFalse  = listPersonsCheck.filter((row) => {
            if(row.userActive === false && !personsChecked.current.has(row._id)){
              return true
            }
            return false; 
          });        
          const orderedClients = [...new_clients_true, ...clientsFalse];
          listContactsRef.current = orderedClients;
          setPersonsList((prev) => { return orderedClients });
          setPersonsListFilter((prev) => { return orderedClients });
          setValidation(personsChecked.current.size && listContactsRef.current.length > 0);
        } 
        // Ajustamos el paginado.
        page.current = result.data.currentPage + 1
        totalPages.current = result.data.totalPages;
      }
      // console.log('📝📝📝:', personsList)
      
      setListPeopleAdded(Object.values(personsCheckedList.current));
      setLoading(false);
      return result;
    } catch (error) {
      console.log('error on actualizar', error);
      return null;
    }
  };

  const getList = async () => {
    try {
      const url = urlAPI_1 + `lists/${id_param}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setList(result.data);
      const { name, description } = result.data;
      reset({ name, description });
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  //#--> Listar las etiquetas
  const getTags = async () => {
    try {
      // setLoading(true);
      const url = urlAPI_1 + `tags`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      // console.log(":: ========>> TAGS ========>> :::", result);
      if(!result.data) {
        console.log("no hay data");
        setListTags([]);
      } else {
        // console.log("si hay lista");
        let list = result.data
        list.push({
          title: "Todos"
        })
        list = list.reverse()
        setListTags(list);
      }
      // setLoading(false);
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  }

  const getCorresp = async () => {
    try {
      const url = urlAPI_2 + "getDataEmail";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("list correspondence-------->>", result);
      setCorrespondence(result.data);
      if (result.message === "Tu petición no tiene cabecera de autorización") {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const actionList = async (inputs) => {
    console.log("personsChecked.current: ", personsChecked.current);
    console.log("validation::::::::::::: ", validation);
    // if (personsChecked.current.length === 0) {
    if (personsChecked.current.size === 0) {
      Swal.fire({
        title: "¡Atención!",
        text: "Debes seleccionar al menos una persona",
        icon: "error"
      });
      return;
    }

    const values = {
      name: inputs.name,
      description: inputs.description,
      persons: Array.from(personsChecked.current).toString()
    };
    console.log("values: ", values);

    let url = urlAPI_1 + `lists/`;
    let method = "POST";
    if(id_param != "new"){
      url = urlAPI_1 + `lists/${id_param}`;
      method = "PUT";
    }

    try {
      setLoading(true);
      const options = { method: method, mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      if (result.code === 1) {
        navigate('/home/lists');
      } else {
        Swal.fire({
          title: "¡Bien!",
          text: result.message,
          icon: result.type,
          confirmButtonText: "Aceptar",
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const searchQuery = (reset = false) => {
    if (page.current > totalPages.current) {
      searchQueryLocal();
    } else {
      // busqueda en db
      fetchPersons(reset);
    }
  };

  const debouncedSearchQuery = useCallback(
    debounce((value) => {
      if (value === "") {
        searchQuery(true);
      } else {
        searchQuery();
      }
    }, 2000),
    []
  );
  
  const handleChange = (e) => {
    const value = e.target.value;
    searchTermRef.current = value;
    setSearchTerm(value);
    debouncedSearchQuery(value);
  };


  const searchQueryLocal = () => {
    console.log("searchQueryLocal");
    var searchResults = personsList.filter((element) => {
      if (
        element.name.toString().toLowerCase().includes(searchTermRef.current.toLowerCase())
      ) {
        return element;
      }
    });
    setPersonsList(searchResults);
    if (searchResults.length === 0) {
      setValidation(false);
    } else {
      setValidation(true)
    }
  };

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (e) => {
    console.log('Seleccionados toooodoosssss:', e.target)
    const isChecked = e.target.checked;
    console.log('Seleccionados toooodoosssss ------ 2 --------:', isChecked)
    setSelectAll(isChecked);
    
    let updatedPersonsList = personsList.map(person => {
      person.userActive = isChecked;
      return person;
    });
    setPersonsList(updatedPersonsList);

    // Actualiza el set de personsChecked y personsCheckedList
    if (isChecked) {
      // Si se selecciona todos, agregar todos los usuarios
      personsChecked.current = new Set(personsList.map(person => person._id));
      personsCheckedList.current = personsList.reduce((acc, person) => {
        acc[person._id] = person;
        return acc;
      }, {});
    } else {
      // Si se deselecciona todos, limpiar los set y listas
      personsChecked.current.clear();
      personsCheckedList.current = {};
    }
    setListPeopleAdded(Object.values(personsCheckedList.current));
    setValidation(personsChecked.current.size > 0 && personsList.length > 0);
  };

  const handlePersonCheck = (e, person) => {
    person.userActive = parseBoolean(e.target.checked);
    console.log('person::::', person)
    // console.log('personsList::::', personsList)
    let new_person_states = personsList.map((row) => {
      if (row._id === person._id) {
        row.userActive = parseBoolean(e.target.checked);
      }
      return row;
    });
    // console.log('new_person_states::::', new_person_states)
    setPersonsList(new_person_states);
    // setListPeopleAdded(...listPeopleAdded, person)

    if(person._id in personsCheckedList.current){
      delete personsCheckedList.current[person._id];
      // delete personsAdd.current
    }
    personsChecked.current.delete(person._id);

    if(person.userActive){
      personsChecked.current.add(person._id);
      personsCheckedList.current[person._id] = person;
      // personsAdd.current = person
    }

    console.log('Data personCheckedList::::', personsCheckedList.current)
    // console.log('💨💨💨 personsAdd.current:', personsAdd.current)
    setListPeopleAdded(Object.values(personsCheckedList.current));
    setValidation(personsChecked.current.size > 0 && personsList.length > 0);
    
    // person.userActive = parseBoolean(e.target.checked);
    // let new_person_states = personsList.map((row) => {
    //   if (row._id === person._id) {
    //     row.userActive = parseBoolean(e.target.checked);
    //   }
    //   return row;
    // });
    // setPersonsList(new_person_states);
    // if(person._id in personsCheckedList.current){
    //   delete personsCheckedList.current[person._id];
    // }
    // personsChecked.current.delete(person._id);

    // if(person.userActive){
    //   personsChecked.current.add(person._id);
    //   personsCheckedList.current[person._id] = person;
    // }
    // console.log('👩‍🦱👩‍🦱📑📑person checked:', personsCheckedList)
    // setValidation(personsChecked.current.length && personsList.length > 0);
  };

  function parseBoolean(value) {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string') return value.toLowerCase() === 'true';
    return false;
    // if (typeof value === 'boolean') {
    //   return value;
    // } else if (typeof value === 'string') {
    //   const lowerCaseValue = value.toLowerCase();
    //   if (lowerCaseValue === 'true') {
    //     return true;
    //   } else if (lowerCaseValue === 'false') {
    //     return false;
    //   }
    // }
    // // Si no es ni booleano ni una cadena válida, devuelve false por defecto
    // return false;
  }

  const handleScroll = (event) => {
    const target = event.target;
    if (segments.length <= 0) {
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        fetchPersons();
      }
    }
  };

  //#--> Funcion para controlar la opcion de ordenar por:
  const handleChange3 = async (e) => {
    setLoading(true)
    const data = e.target.value
    setSelectedSort(data)
    if (data == '2') {
      const sortedItems = personsList.sort((a, b) => a.name.localeCompare(b.name));
      console.log('📑sortedItems======= orden alfabetico ========>>', sortedItems);
      setPersonsList(sortedItems);
    } else if (data == "1") {
      const sortedItems = personsList.sort((a, b) => new Date(b.created) - new Date(a.created));
      console.log('📑sortedItems======== fecha de creación =======>>', sortedItems);
      setPersonsList(sortedItems);
    }
    setLoading(false)
  };

  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };
  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const addSegment = () => {
    setSegments([
      ...segments,
      { attribute: "", operation: "", valor: "" }
    ]);
  };

  const handleInputChange = (index, event) => {
    // console.log('INDEX------->', index)
    // console.log('LEYENDO NAME------->', event.target.name)
    // console.log('LEYENDO DATAAAAA------->', event.target.value)
    const newSegments = [...segments];
    newSegments[index][event.target.name] = event.target.value;
    setSegments(newSegments);
  };

  const removeSegment = (index) => {
    const newSegments = segments.filter((_, i) => i !== index);
    setSegments(newSegments);
  };

  const filterPersonsList = (segments, personsListFilter) => {
    console.log('👩‍💼👩‍🦱👩‍💼👩‍🦱Cantidad de personas:', personsListFilter.length)
    return personsListFilter.filter(person => {
      return segments.every(segment => {
        const { attribute, operation, valor } = segment;
        console.log('atributo:', attribute)
        
        // Verifica si el atributo está en la raíz o en additional_fields
        const personValue = person[attribute] || person.additional_fields?.[attribute];
  
        if (!personValue) return false; // Si el valor no existe, no pasa el filtro
  
        switch (operation) {
          case "contains":
            if (attribute === "tags") {
              return personValue.includes(valor); // Contiene el valor
            } else {
              return personValue.toLowerCase().includes(valor.toLowerCase()); // Contiene el valor
            }
          case "not_contains":
            return !personValue.toLowerCase().includes(valor.toLowerCase()); // No contiene el valor
          case "=":
            return personValue.toLowerCase() === valor.toLowerCase(); // Igual
          case "!=":
            return personValue.toLowerCase() !== valor.toLowerCase(); // Diferente
          case ">":
            return personValue > valor; // Mayor que
          case "<":
            return personValue < valor; // Menor que
          case ">=":
            return personValue >= valor; // Mayor o igual que
          case "<=":
            return personValue <= valor; // Menor o igual que
          default:
            return false; // Operación desconocida
        }
      });
    });
  };

  const validateSegments = () => {
    // Devuelve true si todos los campos en cada segmento están llenos
    return segments.every(segment => {
      return segment.attribute && segment.operation && segment.valor;
    });
  };

  const postFilterContacts = async (e) => {
    e.preventDefault(); // Prevenir que se recargue la página

    if (!validateSegments()) {
      setSegmentsValidate(true)
      return;
    }

    setModalFilter(false);
    setSegmentsValidate(false)
    // setSegments([])
    console.log('📑', segments)
    if (segments.length > 0) {
      const data = JSON.stringify(segments)
      const reset = false
      await fetchPersons(reset, encodeURIComponent(data))
    } else {
      await fetchPersons()
    }
    // const filteredPersons = filterPersonsList(segments, personsListFilter);
    // console.log('🚩🚩', filteredPersons);
    // setPersonsList(filteredPersons)
  }

  return (
    <Fragment>
      {loading ? <div className="content-spinner"><Spinner /></div> : null}
      <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-1" >
        <div style={{ height: "45px", lineHeight: "95px" }}>
          <button onClick={() => navigate('/home/lists')} type="button" className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex" >
            <FaAngleLeft /> Listas
          </button>
        </div>

        <Header />

      </div>

      <section className="module-create-user" style={{ width: "100%", height: "88%" }}>
        <header className="message" style={{height:"9%"}}>
          <div id="tittleFormProduct"><h1>{title} {list.name}</h1></div>
        </header>

        <form onSubmit={handleSubmit(actionList)} className="Form-create-new-list" style={{height:"91%"}}>
          <div className="form-create-list m-0 px-3" style={{height:"87%"}}>

            <div className="left-content mt-3">
              <div className="module-section" id="product-basic">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label htmlFor="name">Nombre de la lista: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      id="name"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "El nombre es requerido.",
                        },
                      })}
                    />
                  </div>
                  {errors.name && (<Alert>* {errors.name.message}</Alert>)}
                </div>

                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Descripcion de la lista: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="text"
                      name="description"
                      id="description"
                      {...register("description", {
                        required: {
                          value: true,
                          message: "La descripcion es requerida.",
                        },
                      })}
                    />
                  </div>
                  {errors.description && (<Alert>* {errors.description.message}</Alert>)}
                </div>

                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <div className="d-flex justify-content-between">
                    <label>Contactos agregados: </label>
                    <span>Cant: {listPeopleAdded.length} </span>
                  </div>
                  <div className="su-control-group">
                    {listPeopleAdded.length <= 0 ?
                      <h3 className="text-center mt-3">Sin contactos</h3>
                    :
                      <ul id="content-segments" style={{height:"38vh", overflow:"auto"}}>
                        {listPeopleAdded.length > 0 && listPeopleAdded.map((person, k) => {
                          // console.log('persona agregada::::', person)

                          const initialWords = person.name.split(' ');
                          const firstWord = initialWords[0];
                          const secondWord = initialWords[1];
                          let icon = ""
                          if (secondWord === undefined) {
                            icon = firstWord[0];
                          } else {
                            icon = firstWord[0] + secondWord
                          }

                          return (
                            <li
                              key={k}
                              id="li-lists"
                              className="bg-light m-t-1 px-3 py-2 row w-100 d-flex justify-content-around align-items-center"
                              style={{borderBottom:"1px solid"}}
                            >
                              <div className="col-1">
                                <Avvvatars value={icon} className="icon-avatar" />
                              </div>
                              <div className="col-3">
                                <span className="">{person.name}</span>
                              </div>
                              <div className="col-3">
                                <span className="">{person.phone}</span>
                              </div>
                              <div className="col-3 d-flex justify-content-center">
                                <input
                                  type="checkbox"
                                  // value={parseBoolean(person.userActive)}
                                  className="cmn-toggle cmn-toggle-round-flat d-none"
                                  name={person._id}
                                  id={person.name}
                                  // defaultChecked={parseBoolean(person.userActive)}
                                  checked={parseBoolean(person.userActive)}
                                  onChange={(e) => {
                                    handlePersonCheck(e, person);
                                  }}
                                />
                                <label className="" htmlFor={person.name}></label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="right-content pt-3" style={{height:"100%"}}>
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0 pt-1 h-100">
                <div className='' style={{ marginTop: "-15px", height:"15%" }}>
                  <div className="pb-2 w-100 d-flex justify-content-center" style={{ paddingTop: "10px" }}>
                    <label>Agregar contactos a la lista: </label>
                    {validation == 0 && personsChecked.current.size > 0 ? (
                      <div className="error-message">
                        <Alert className="w-100">* Debes seleccionar al menos una persona</Alert>
                      </div>
                    ) : null}
                  </div>
                  <div id="content-filters" className="pr-0 d-flex align-items-center justify-content-between w-100" style={{ float: "right" }}>

                    <div className='d-flex align-items-center'>
                      <div className='d-flex align-items-center mr-2'>
                        <label className="mb-0">Ordenar por: </label>
                        <select
                          name="attribute"
                          className="form-control"
                          value={selectedSort}
                          onChange={(e) => handleChange3(e)}
                        >
                          <option value="1" key="1">Fecha de Creación</option>
                          <option value="2" key="2">Orden alfabetico</option>
                        </select>
                      </div>

                      <div style={{ position: "relative", display: "flex" }}>
                        <HiAdjustmentsHorizontal
                          className="icon-filter-2 f-36 pointer ml-2"
                          onClick={() => {
                            setModalFilter(true);
                            getIsDark();
                          }}
                        />
                        {segments.length > 0 && (
                          <div
                            className="number-messages"
                            style={{
                              fontSize: "12px",
                              position: "absolute",
                              top: "-4px", // Ajusta según sea necesario
                              right: "-8px", // Ajusta según sea necesario
                              backgroundColor: "#00a884",
                              // backgroundColor: "red",
                              color: "white",
                              borderRadius: "50%",
                              padding: "0px 5px",
                              width: "18px",
                              height: "18px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {segments.length}
                          </div>
                        )}
                      </div>
                    </div>
                    
                    <div className='d-flex align-items-center'>

                      <div className="d-flex align-items-center mr-4">
                        <label className="mb-0">Seleccionar todos: </label>
                        <input
                          type="checkbox"
                          // value={parseBoolean(person.userActive)}
                          className="cmn-toggle cmn-toggle-round-flat"
                          name="all"
                          id="all"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                        <label className="ml-1 mb-0" htmlFor="all"></label>
                      </div>
                      
                      <div className="form-inline">
                        <div className="su-control mt-1">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Búsqueda"
                            value={searchTerm}
                            onChange={handleChange}
                          />
                          <button
                            className="btn btn-info"
                            type="button"
                            id="buttonSearchProductBack"
                            style={{ height: "34px" }}
                            onClick={() => {
                              searchQuery();
                            }}
                          >
                            <BsSearch />
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="container-fluid mt-2 px-0 mr-2 table-list-users" style={{ height: "80%", overflow: "auto", width: "99%" }} onScroll={handleScroll}>
                  <ul>
                    {personsList.length > 0 && personsList.map((person, k) => {
                      const initialWords = person.name.split(' ');
                      const firstWord = initialWords[0];
                      const secondWord = initialWords[1];
                      let icon = ""
                      if (secondWord === undefined) {
                        icon = firstWord[0];
                      } else {
                        icon = firstWord[0] + secondWord
                      }

                      return (
                        <li key={person._id} id="li-lists" className="bg-light b-primary m-t-5 px-3 py-2 row w-100 d-flex justify-content-around align-items-center">
                          <div className="col-1">
                            <Avvvatars value={icon} className="icon-avatar" />
                          </div>
                          <div className="col-3">
                            <span className="">{person.name}</span>
                          </div>
                          <div className="col-3">
                            <span className="">{person.email}</span>
                          </div>
                          <div className="col-2">
                            <span className="">{person.phone}</span>
                          </div>
                          <div className="col-2">
                            <input
                              type="checkbox"
                              // value={parseBoolean(person.userActive)}
                              className="cmn-toggle cmn-toggle-round-flat"
                              name={person._id}
                              id={person.name}
                              // defaultChecked={parseBoolean(person.userActive)}
                              checked={parseBoolean(person.userActive)}
                              onChange={(e) => {
                                handlePersonCheck(e, person);
                              }}
                            />
                            <label className="ml-4" htmlFor={person.name}></label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {!personsList.length && <h1 className="text-center mt-3">No existen registros en el sistema</h1>}
                </div>

                {/* <footer className="pb-0 pt-1 bg-white">
                  <div className="row">
                    <div className="col-md-8">
                    </div>
                  </div>
                </footer> */}

              </div>
            </div>
          </div>

          <footer style={{height:"13%"}}>
            <div className="row">
              <div className="col-sm-6">

              </div>
              <div className="col-sm-6 right">
                <a href="javascript:;" className="btn btn-lg btn-default mr-1"
                  onClick={() => navigate('/home/lists')}
                >Volver</a>

                <input
                  className="btn btn-lg btn-info"
                  value="Guardar"
                  type="submit"
                  // disabled={validation}
                  onClick={() => console.log('clickkk')}
                />
              </div>
            </div>
          </footer>

        </form>

      </section>


      {/* Modal para filtrar contactos */}
      <Modal
        status={modalFilter}
        changeStatus={setModalFilter}
        title='Filtrar contactos'
        width={"800px"}
      >
        <Content>
          <form onSubmit={postFilterContacts} style={{ width: "100%" }}>
            <div className="">
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <div id="content-segments" style={{maxHeight:"50vh", overflow:"auto"}}>
                  {segments.map((segment, index) => (
                    <div key={index} className="su-segmentation-disable d-flex align-items-center justify-content-around mb-2">
                      <div className="mr-2">
                        <select
                          name="attribute"
                          className="form-control same-width"
                          value={segment.attribute}
                          onChange={(e) => handleInputChange(index, e)}
                        >
                          <option value="" key="0" disabled>Atributo</option>
                          <option value="tags" key="213">Etiqueta</option>
                          {correspondence && correspondence.map((element, k) => (
                            <option value={element.field} key={k}>
                              {element.fieldName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mr-2">
                        <select
                          name="operation"
                          className="form-control same-width"
                          value={segment.operation}
                          onChange={(e) => handleInputChange(index, e)}
                        >
                          <option value="" key="0" disabled>Operación</option>
                          <option value="contains" key="1">Contiene</option>
                          {segment.attribute !== "tags" && (<>
                            <option value="not_contains" key="2">No contiene</option>
                            <option value="=" key="3">{"="}</option>
                            <option value=">" key="4">{">"}</option>
                            <option value="<" key="5">{"<"}</option>
                            <option value=">=" key="6">{">="}</option>
                            <option value="<=" key="7">{"<="}</option>
                            <option value="!=" key="8">{"!="}</option>
                          </>)}
                        </select>
                      </div>
                      <div className="mr-2">
                        {segment.attribute === "tags" ? (
                          <select
                            className="form-control same-width"
                            name="valor"
                            value={segment.valor}
                            onChange={(e) => handleInputChange(index, e)}
                          >
                            <option value="" disabled>Selecciona una etiqueta</option>
                            {listTags.map((etiqueta, idx) => (
                              <option key={idx} value={etiqueta._id}>
                                {etiqueta.title}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            className="form-control same-width"
                            type="text"
                            name="valor"
                            placeholder="Valor"
                            value={segment.valor}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        )}
                      </div>
                      <div>
                        <BsTrash3 className="pointer f-30" onClick={() => removeSegment(index)} />
                      </div>

                    </div>
                  ))}
                </div>
                <div className="text-center mt-3">
                  {segmentsValidate && (
                    <div className="mb-2">
                      <Alert>* Debes llenar todos los campos.</Alert>
                    </div>
                  )}
                  <a
                    href="javascript:;"
                    className="btn btn-lg btn-default mr-1"
                    onClick={addSegment}
                  >
                    Agregar segmento nuevo +
                  </a>
                </div>
              </div>
              
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalFilter((prevMessage) => { return false});
                }}
              >
                Cancelar
              </a>
              <input
                className="btn btn-lg btn-info"
                // type="button"
                type="submit"
                value="Aceptar"
                // onClick={() => {
                //   // filterByTags()
                //   setModalFilter((prevMessage) => { return false });
                //   setSegments([])
                //   console.log('📑', segments)
                //   const filteredPersons = filterPersonsList(segments, personsList);
                //   console.log('🚩🚩', filteredPersons);
                //   setPersonsList(filteredPersons)
                // }}
              />
            </div>
          </form>


        </Content>
      </Modal>

    </Fragment>
  );
};

export default List;

const Button = styled.button`
    /*width: 100px;
    padding: 15px;
    font-weight: 900;
    margin-bottom: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);*/
`

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 85px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  .content-img img:hover {
    opacity: 0.6;
  }
`;

const Container = styled.div`
  background: #fff;
  border-radius: 12px;
  border: 1px solid #ddd;
  padding-bottom: 20px;
  
  .left-content {
    width: 60%;
  }

  .left-content label {
    font-weight: 900;
    font-size: 16px;
  }

  .left-content input {
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px;
    border-radius: 12px;
    border: 1px solid #cccccc;
    width: 85%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    height: 45px;
  }

  .right-content {
    width: 90%;
    margin-top: 25px;
  }

  .right-content table {
    border: 1px solid #ddd;
  }

  .right-content .table-list-users {
    border-bottom: 1px solid #ddd;
    border-radius: 12px;
  }

  .form-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  @media (max-width: 660px) {
    .form-content {
      flex-direction: column;
    }

    .form-field {
      margin-top: 10px;
    }
  }

  .form-field {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 40px auto;
  }

  .form-field label {
    width: 100%;
    text-align: center;
  }

  .table-content {
    width: 95%;
    margin: 0 auto;
    height: 460px;
    overflow: auto;
  }

  .thead {
    font-weight: 900;
  }

  .checkbox {
      width: 30px;
      height: 30px;
      margin: 0 auto;
  }

  .td-check {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
