import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import Iphone from "../../imgs/IPhone_X.png";
import Iphone2 from "../../imgs/Iphone_2.png";
import mImage from "../../imgs/medio-img.png";
import mVideo from "../../imgs/medio-video.png";
import mDocument from "../../imgs/medio-doc.png";
import Picker from "emoji-picker-react";
import useAPI from "../../hooks/useAPI";
import Spinner from "../plugins/Spinner";
import dayjs from "dayjs";
import { FaAngleLeft } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { TiExport } from "react-icons/ti";

//Componentes
import Header from "../layout/Header";

const TemplateWhatsappEdit = () => {
  const categories = [
    {
      id: 1,
      name: "Transacciones",
      description:
        "Envía notificaciones de la cuenta y los pedidos, alertas y más para compartir información importante.",
    },
    {
      id: 2,
      name: "Marketing",
      description:
        "Envía ofertas promocionales, anuncios de productos y más para aumentar el reconocimiento y la interacción.",
    },
    {
      id: 3,
      name: "Contraseñas de un solo uso",
      description:
        "Envía códigos que permitan a los clientes acceder a su cuenta.",
    },
  ];
  
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("createwsp");
  const [date, setDate] = useState("");
  const [categorys, setCategorys] = useState([]);
  const [lenguagesList, setLenguagesList] = useState([]);
  const [viewList, setViewList] = useState(false);
  

  const [categoryTemplate, setCategoryTemplate] = useState("");
  const [viewCategory, setViewCategory] = useState(false);

  const [headerType, setHeaderType] = useState("");
  const [selectedMedia, setSelectedMedia] = useState("");
  const [textHeader, setTextHeader] = useState("");
  const [textFooter, setTextFooter] = useState("");
  const [buttonType, setButtonType] = useState("");
  const [actionType, setActionType] = useState("callThePhoneNumber");
  const [actionType2, setActionType2] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonTextGoToWebsite, setButtonTextGoToWebsite] = useState("");
  const [buttonText2, setButtonText2] = useState("");
  const [buttonTextGoToWebsite2, setButtonTextGoToWebsite2] = useState("");
  const [buttonTextFastAnswer, setButtonTextFastAnswer] = useState("");
  const [buttonTextFastAnswer2, setButtonTextFastAnswer2] = useState("");
  const [addAnotherButton, setAddAnotherButton] = useState(false);
  const [addAnotherButtonFastAnswer, setAddAnotherButtonFastAnswer] = useState(false);
  const [bottons, setBottons] = useState(false);
  const [header, setHeader] = useState(false);
  const [count, setCount] = useState(1);
  const [typeMessages, setTypeMessages] = useState('');
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [notVariable, setNotVariable] = useState(false);
  const [warningMessages,setWarningMessages] = useState(false);
  const [data, setData] = useState({});
  const [urlType, setUrlType] = useState('');
  const [urlType2, setUrlType2] = useState('');

  //Nuevos estados para pintar
  const [templateName, setTemplateName] = useState('');
  const [templateLanguage, setTemplateLanguage] = useState('');
  const [templateBody, setTemplateBody] = useState('');
  const [templateButtons, setTemplateButtons] = useState('');


  //# Token para permisos de los servicios
  const token = localStorage.getItem("USER_TOKEN");
  const {urlAPI, urlAPI_2, fetchWithAuth} = useAPI();
  const { id } = useParams();

  //# React-hook-form para establecer validaciones
  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({
    mode: "all",
    defaultValues: {
      category: "",
      name: "",
      languages: [],
      headerType: "",
      headerText: "",
      media: "",
      body: "",
      footer: "",
      buttonType: "",
      fastAnswerText: "",
      fastAnswerText2: "",
      actionType: "",
      actionType2: "",
      buttonTextAction: "",
      buttonTextAction2: "",
      buttonTextActionGoToWebsite: "",
      buttonTextActionGoToWebsite2: "",
      country: "",
      country2: "",
      phoneNumber: "",
      phoneNumber2: "",
      urlType: "",
      urlType2: "",
      websiteUrl: "",
      websiteUrl2: ""
    },
  });

  const getTemplate = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + `templates/${id}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      const res = result.data;
      console.log('DATA: : : ', res);

      //- Generamos la hora exacta para pintar en la vista previa
      const hour = new Date();
      setDate(hour);

      const { category,name,language } = res;
      setTemplateName(name);
      setCategoryTemplate(category);
      setTemplateLanguage(language);

      //- Vamos a obtener objeto por cada contenido de la plantilla
      const content = res.content;
      for(var cont of content){
        var objHeader = {};
        if (cont.type === 'HEADER') {
          objHeader = cont;
          console.log('=> SI HAY HEADER', objHeader);
          setHeaderType(objHeader.format);
          setTextHeader(objHeader.text);
        }
        
        var objBody = {};
        if (cont.type === 'BODY') {
          objBody = cont;
          console.log('=> SI HAY BODY', objBody);
          setTemplateBody(objBody.text);
        }
        
        var objFooter = {};
        if (cont.type === 'FOOTER') {
          objFooter = cont;
          console.log('=> SI HAY FOOTER', objFooter);
          setTextFooter(objFooter.text);
        }

        var objButtons = {};
        if (cont.type === 'BUTTONS') {
          objButtons = cont;
          console.log('=> SI HAY BUTTONS', objButtons);
          setTemplateButtons(objButtons);
        }
      }

      // const { buttonType } = res.content.buttons;
      // const { actionType,actionType2 } = res.content.buttons.callToAction;
      // const { buttonTextAction,country,phoneNumber, } = res.content.buttons.callToAction.callThePhoneNumber[0];
      // const { buttonTextAction2,country2,phoneNumber2 } = res.content.buttons.callToAction.callThePhoneNumber2[0];
      // const { buttonTextActionGoToWebsite,urlType,websiteUrl, } = res.content.buttons.callToAction.goToWebsite[0];
      // const { buttonTextActionGoToWebsite2,urlType2,websiteUrl2 } = res.content.buttons.callToAction.goToWebsite2[0];
      // const { fastAnswerText } = res.content.buttons.fastAnswer[0];    
      // const { fastAnswerText2 } = res.content.buttons.fastAnswer[1];

      
      
      
      // reset({ category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,buttonTextAction,buttonTextAction2,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2});

      // setUrlType(urlType);
      // setUrlType2(urlType2);

      // setHeaderType(headerType);
      // setSelectedMedia(media);
      // setTextHeader(headerText);
      // setInputStr(body);
      
      // setButtonType(buttonType);
      // setActionType(actionType);
      // setButtonText(buttonTextAction);
      // setButtonText2(buttonTextAction2);
      // setActionType2(actionType2);
      // setButtonTextGoToWebsite(buttonTextActionGoToWebsite);
      // setButtonTextGoToWebsite2(buttonTextActionGoToWebsite2);
      // setButtonTextFastAnswer(fastAnswerText);
      // setButtonTextFastAnswer2(fastAnswerText2);
      // if (actionType2) {setAddAnotherButton(true)}else{setAddAnotherButton(false)}
      // if (fastAnswerText2) {setAddAnotherButtonFastAnswer(true)}else{setAddAnotherButtonFastAnswer(false)}

      // console.log('====', selectedMedia);

      setLoading(false);

    } catch (error) {
      console.log(error);
    }
  };

  const putTemplate = async () => {
    const {category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2,buttonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2} = watch();

    const removeSpaces = inputStr.trim();
    const initial = removeSpaces.startsWith("{{");
    const final = removeSpaces.endsWith("}}");
    console.log('Posición inicial ', initial);
    console.log('Posición final ', final);
    if (initial || final) {
      console.log('No puedes iniciar y finalizar el contenido con una variable');
      setWarningMessages(true);

    }else{

      console.log('Correcto..........');
      setWarningMessages(false);

      const mainObject = {
        name,
        type: typeMessages,
        content: {
          category,
          name,
          languages,
          header: {
              headerType,
              headerText,
              media
          },
          body,
          footer,
          buttons: {
              buttonType,
              callToAction: {
                  actionType,
                  callThePhoneNumber: [
                      {
                          buttonTextAction,
                          country,
                          phoneNumber
                      }
                  ],
                  goToWebsite: [
                      {
                          buttonTextActionGoToWebsite,
                          urlType,
                          websiteUrl
                      }
                  ],
                  actionType2,
                  callThePhoneNumber2: [
                      {
                          buttonTextAction2,
                          country2,
                          phoneNumber2 
                      }
                  ],
                  goToWebsite2: [
                      {
                          buttonTextActionGoToWebsite2,
                          urlType2,
                          websiteUrl2 
                      }
                  ]
              },
              fastAnswer: [
                  {fastAnswerText },
                  {fastAnswerText2 }
              ]
          }
        }
      }
      console.log('Objetos, datos actualizados: ', mainObject);
      try {
        setLoading(true);
        const url = urlAPI + `templates/${id}`;
        const options = { method: "PUT", mode: "cors", body: JSON.stringify(mainObject)};
        const response = await fetchWithAuth(url, options);
        const result = await response.json();

        //# PUT Completado, se reinician lo campos
        navigate("/home/templates");
        setLoading(false);

        //# Confirmacion del cambio
        Swal.fire({
          title: "¡Bien!",
          text: result.message,
          icon: result.type,
          confirmButtonText: "Aceptar",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getTemplate(id);
    // getLanguages();
    setCategorys(categories);
    // getTypeMessages();
  }, []);

  const getTypeMessages = async () => {
    try {
      setLoading(true);
      const url = urlAPI + "messagetypes";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      const typeId = result.data[1]._id;
      setTypeMessages(typeId)
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  const getLanguages = async () => {
    try {
      setLoading(true);
      const url = urlAPI + "languages";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      const listL = result.data;
      console.log('Lenguajes nuevos', listL);
      setLenguagesList(listL);
      setLoading(false);

    } catch (error) {
      console.log(error)
    }
  }

  const onEmojiClick = (event, emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    // setShowPicker(false);
  };

  const onListLenguages = () => {
    const { languages } = watch();
  };

  const onHeaderType = (e) => {
    const {category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2,buttonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2} = watch();

    reset({ category,name,languages,headerType: e.target.value,headerText: "",media: "",body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2,buttonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2});
    setSelectedMedia("");
    setTextHeader("");
    setHeaderType(e.target.value);
  };

  const onAddVariable = () => {
    setCount(count + 1);
    if (count <= 10) {
      const variable = `{{${count}}}`;
      const resContent = inputStr + " " + variable + " ";
      setInputStr(resContent);
      setNotVariable(false);
    }else{
      setNotVariable(true);
    }
  };

  const onBottonType = (e) => {
    setButtonType(e.target.value);
    const {category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2,buttonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2} = watch();

    reset({ category,name,languages,headerType,headerText,media,body,footer,buttonType: e.target.value ,fastAnswerText: "",fastAnswerText2: "",actionType: "",actionType2: "",buttonTextAction: "",buttonTextActionGoToWebsite: "",buttonTextActionGoToWebsite2: "",buttonTextAction2: "",country: "",country2: "",phoneNumber: "",phoneNumber2: "",urlType: "",urlType2: "",websiteUrl: "",websiteUrl2: ""});

    setButtonText("");
    setButtonText2("");
    setButtonTextGoToWebsite("");
    setButtonTextGoToWebsite2("");
    setButtonTextFastAnswer("");
    setButtonTextFastAnswer2("");
    setAddAnotherButton(false);
    setAddAnotherButtonFastAnswer(false);
    setActionType2("");
    
  };

  const onActionType = (e) => {
    setActionType(e.target.value);
    const {category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2,buttonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2} = watch();

    reset({ category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType: e.target.value,actionType2,buttonTextAction: "",buttonTextActionGoToWebsite: "",buttonTextActionGoToWebsite2: "",buttonTextAction2: "",country: "",country2: "",phoneNumber: "",phoneNumber2: "",urlType: "",urlType2: "",websiteUrl: "",websiteUrl2: ""});
    setButtonText("");
    setButtonText2("");
    setButtonTextGoToWebsite("");
    setButtonTextGoToWebsite2("");
  };

  const onActionType2 = (e) => {
    setActionType2(e.target.value);
    console.log("tipo de acción2", e.target.value);
    setButtonText2("");
  };

  const onDeleteBotton = () => {
    const {category, name,  languages, headerType, headerText, media, body, footer, buttonType, fastAnswerText, fastAnswerText2, actionType, actionType2, buttonTextAction, buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2, buttonTextAction2, country, country2, phoneNumber, phoneNumber2,urlType, urlType2, websiteUrl, websiteUrl2,} = watch();
    setAddAnotherButton(false);
    reset({ category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2: "",buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2: "",buttonTextAction2: "",country,country2: "",phoneNumber,phoneNumber2: "",urlType,urlType2: "",websiteUrl,websiteUrl2: ""});
    setButtonText2("");
    setButtonTextGoToWebsite2("");
    setActionType2("");
  };

  const onDeleteBottonFastAnswer = () => {
    const {category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2,buttonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2,} = watch();
    console.log("Eliminando....");
    setAddAnotherButtonFastAnswer(false);
    reset({category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2: "",actionType,actionType2: "",buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2: "",buttonTextAction2: "",country,country2: "",phoneNumber,phoneNumber2: "",urlType,urlType2: "",websiteUrl,websiteUrl2: ""});
    setButtonTextFastAnswer2("");
  };

  const onKeyUp = (e) => {
    const {category,name,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2,buttonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2} = watch();
    const vlr = e.target.value; 
    const result = vlr.replace(/\s+/g,"_");
    reset({category,name: result,languages,headerType,headerText,media,body,footer,buttonType,fastAnswerText,fastAnswerText2,actionType,actionType2,buttonTextAction,buttonTextActionGoToWebsite,buttonTextActionGoToWebsite2,buttonTextAction2,country,country2,phoneNumber,phoneNumber2,urlType,urlType2,websiteUrl,websiteUrl2})
  }

  const navigate = useNavigate();
  const param = "{{1}}";

  return (
    <Fragment>
      {loading ? <div className="content-spinner"><Spinner /></div> : null}
      
      <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
        <div style={{ height: "45px", lineHeight: "95px" }}>
          <button id="dos" onClick={() => {reset();navigate("/home/templates")}} type="button" className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex align-items-center">
            <FaAngleLeft style={{ fontSize: "22px" }}/>Plantillas
          </button>
        </div>

        <Header />
      </div>

      <section className="module-create-user" style={{ width: "100%" }}>
        <header className="message">
          <div id="tittleFormProduct" className="d-flex justify-content-around">
            <div className="su-with-prefix su-with-suffix su-segmentation-disable d-flex mt-0 align-items-center justify-content-center" style={{width:"33.3%"}}>
              <h1 className="mb-0">Nombre: </h1>
              <div className="su-control-group" style={{width: "70%"}}>
                <input
                  className="form-control pl-2 border-0 p-0"
                  defaultValue={templateName}
                  type="text"
                  name="name"
                  id="name"
                  style={{ background: "transparent" }}
                  // {...register("name", {
                  //   required: {
                  //     value: true,
                  //     message: "El nombre es requerido.",
                  //   },
                  // })}
                  // onKeyUp={onKeyUp}
                />
              </div>
            </div>

            <div className="su-with-prefix su-with-suffix su-segmentation-disable d-flex mt-0 align-items-center justify-content-center" style={{width:"33.3%"}}>
              <h1 className="mb-0">Categoría: </h1>
              <div className="position-relative">
                <input
                  className="form-control pl-2 border-0 p-0"
                  type="text"
                  name="category"
                  id="category"
                  readOnly
                  value={categoryTemplate}
                  // onClick={() => {viewCategory ? setViewCategory(false) : setViewCategory(true)}}
                />
                {/* {viewCategory ? (
                  <div className="category-list position-absolute" style={{width: "265px"}}>
                    {categorys.length > 0 && categorys.map((categ, k) => (
                        <div key={k} className="item-list">
                          <input
                            type="radio"
                            name={categ.id}
                            id={categ.id}
                            className="form-check-input mt-3"
                            value={categ.id}
                            {...register("category", {
                              required: {
                                value: true,
                                message:
                                  "El tipo de plantilla es requerido.",
                              },
                            })}
                            onChange={(e) => setCategoryTemplate(e.target.value)}
                          />
                          <label htmlFor={categ.id} className="form-check d-flex mb-0" key={categ.id} style={{padding: "5px 2px 5px 1.25rem"}}>
                            <div>
                              <label htmlFor={categ.id} className="form-check-label pl-3 f-w-400">{categ.name}</label>
                            </div>
                          </label>
                        </div>
                      ))}
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="su-with-prefix su-with-suffix su-segmentation-disable d-flex mt-0 align-items-center justify-content-center" style={{width:"33.3%"}}>
              <h1 className="mb-0">Idioma: </h1>
              <div className="su-control-group">
                <input
                  className="form-control pl-2 border-0 p-0"
                  defaultValue={templateLanguage}
                  type="text"
                  name="name"
                  id="name"
                  style={{ background: "transparent" }}
                />
              </div>
            </div>

          </div>
        </header>

        <form className="Form-create-new-template" onSubmit={handleSubmit(putTemplate)}>
          <div className="d-flex">
            {/**************** COLUMNA 1 ****************/}
            {/*<div className="colum-1 mr-0">
              <div className="form-check p-3">
                <label htmlFor="select">Idioma: <span style={{fontWeight: 100}}>{templateLanguage}</span></label>

                 <div className="mt-3"
                  style={{
                    background: "#fff",
                    width: "112%",
                    marginLeft: "-14px",
                    paddingRight: "0",
                    paddingLeft: "0",
                  }}
                >
                  <ul className="o-auto d-flex flex-column justify-content-center align-items-center">
                    {lenguagesList && lenguagesList.map((list, k) => (
                        <li key={k} className="row w-90 align-items-center">
                          <input
                            name={list.description}
                            id={list.description}
                            type="checkbox"
                            value={list.id}
                            className="cmn-toggle cmn-toggle-round-flat"
                            {...register("languages", {
                              required: {
                                value: true,
                                message: "Obligatoria",
                              },
                            })}
                          />
                          <label onClick={() => onListLenguages()} className="ml-0" htmlFor={list.description}></label>
                          <span className="col-8">{list.description}</span>
                        </li>
                      ))}
                  </ul>
                </div> 
              </div>
            </div>*/}

            {/**************** COLUMNA 2 ****************/}
            <div className="colum-2">
              <form className="Form-create-new-template">
                <div className="border-bottom mb-0 px-4 pt-3 pb-3">
                  <div>
                    <div id="tittleFormProduct">
                      <h3>Contenido del mensaje.</h3>
                    </div>
                  </div>
                </div>

                {/* ---HEADER--- */}
                {headerType &&
                  <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                    <div className="d-flex">
                      <h4>Encabezado</h4>
                    </div>
                    <div className="form-check mx-0 pl-0">
                      <div className="d-flex">
                        {headerType === "TEXT" ? (
                          <div style={{ width: "77%" }}>
                            <div className="su-control-group">
                              <input
                                className="form-control border-0"
                                type="text"
                                name="headerText"
                                id="headerText"
                                maxLength="60"
                                value={textHeader}
                                readOnly
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {headerType === "none" ? null : headerType === "IMAGE" | headerType === "VIDEO" | headerType === "DOCUMENT" | headerType === "LOCATION" ? (<>
                      <div className="type-medios">
                        <div className="content-type-medios">

                          <div className="t-image">
                            <input
                              type="radio"
                              name="media"
                              id="1"
                              className="form-check-input mt-3"
                              value="1"
                              checked = {headerType === "IMAGE" ? true : false}
                            />
                            <label htmlFor="1" className="form-check d-flex">
                              <div className="cont-medio">
                                <div className="cont-all">
                                  <div></div>
                                  <span>Imagen</span>
                                </div>
                              </div>
                            </label>
                          </div>

                          <div className="t-video">
                            <input
                              type="radio"
                              name="media"
                              id="2"
                              className="form-check-input mt-3"
                              value="2"
                              checked = {headerType === "VIDEO" ? true : false}
                            />
                            <label htmlFor="2" className="form-check d-flex">
                              <div className="cont-medio">
                                <div className="cont-all">
                                  <div></div>
                                  <span>video</span>
                                </div>
                              </div>
                            </label>
                          </div>

                          <div className="t-document">
                            <input
                              type="radio"
                              name="media"
                              id="3"
                              className="form-check-input mt-3"
                              value="3"
                              checked = {headerType === "DOCUMENT" ? true : false}
                            />
                            <label htmlFor="3" className="form-check d-flex">
                              <div className="cont-medio">
                                <div className="cont-all">
                                  <div></div>
                                  <span>Documento</span>
                                </div>
                              </div>
                            </label>
                          </div>

                          <div className="t-location">
                            <input
                              type="radio"
                              name="media"
                              id="4"
                              className="form-check-input mt-3"
                              value="3"
                              checked = {headerType === "LOCATION" ? true : false}
                            />
                            <label htmlFor="3" className="form-check d-flex">
                              <div className="cont-medio">
                                <div className="cont-all">
                                  <div></div>
                                  <span>Ubicación</span>
                                </div>
                              </div>
                            </label>
                          </div>

                        </div>
                      </div>

                    </>) : null}
                  </div>
                }
                
                {/* ---BODY--- */}
                <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                  <div className="d-flex">
                    <h4>Texto</h4>
                  </div>
                  {/* <p>
                    Introduzca el texto de su mensaje en el idioma que haya seleccionado.
                  </p> */}
                  <div className="mt-0 su-control su-segmentation-disable w-100">
                    {/* <input className="form-control" type="text" /> */}
                    <textarea 
                      name="body" 
                      id="body" 
                      className="form-control border-0" 
                      maxLength="1024" 
                      defaultValue={templateBody}
                      readOnly
                      // {...register("body", {
                      //   required: {
                      //     value: true,
                      //     message: "El texto es requerido.",
                      //   },
                      // })}
                      // onChange={(e) => setInputStr(e.target.value)}
                      style={{ height: "135px" }}
                    >
                      {/* {templateBody} */}
                    </textarea>
                  </div>
                  {/* {warningMessages &&
                    <div style={{backgroundColor: "rgba(255,241,204,1)"}}>
                      <ul>
                        <li style={{padding: "10px"}}><i className="icon-attention" style={{fontSize: "19px", color: "rgba(255,186,0,1)"}}/> El texto no puede contener parámetros variables al principio o al final.</li>
                      </ul>
                    </div>
                  }
                  
                  {errors.body && <Alert>* {errors.body.message}</Alert>}
                  {notVariable && <Alert>* No puedes agregar mas variables</Alert>} */}

                  {/* <div className="d-inline-block">
                    <div className="picker-container">
                      <i className={showPicker
                            ? "icon-smile emoji-icon active"
                            : "icon-smile emoji-icon"
                        }
                        style={{ fontSize: "22px" }}
                        onClick={() => setShowPicker((val) => !val)}
                      />

                      {showPicker && (
                        <Picker pickerStyle={{
                            width: "100%",
                            left: "35px",
                            position: "absolute",
                            zIndex: "999",
                          }}
                          onEmojiClick={onEmojiClick}
                          groupNames={{
                            smileys_people: "Emoticones y personas",
                            animals_nature: "Animales y naturaleza",
                            food_drink: "Alimentos y bebidas",
                            travel_places: "Viajes y lugares",
                            activities: "Actividades",
                            objects: "Objetos",
                            symbols: "Símbolos",
                            flags: "Banderas",
                            recently_used: "Recientes",
                          }}
                        />
                      )}
                    </div>

                    <div className="ml-5 mt-1">
                      <div>
                        <span className="add-variable" style={{ cursor: "pointer" }} onClick={onAddVariable}>+ Agregar variable</span>
                      </div>
                    </div>
                  </div> */}
                </div>
                
                {/* ---FOOTER--- */}
                {textFooter &&
                  <div className="border-bottom mb-1 px-4 pt-3 pb-4">
                    <div className="d-flex">
                      <h4>Pie de pagina</h4>
                      {/* <span className="optional ml-3"> Opcional</span> */}
                    </div>
                    {/* <p>Agregue una línea corta de texto al final de su plantilla de mensaje.</p> */}
                    <div className="mt-0 su-control su-segmentation-disable w-75">
                      <input
                        className="form-control border-0"
                        type="text"
                        name="footer"
                        maxLength="60"
                        value={textFooter}
                        readOnly
                        // {...register("footer")}
                        // onChange={(e) => setTextFooter(e.target.value)}
                      />
                    </div>
                  </div>
                }
                
                {/* ---BOTONES--- */}
                {templateButtons &&
                  <div className="mb-1 px-4 pt-3 pb-4">
                    <div className="d-flex">
                      <h4>Botones</h4>
                    </div>
                    <div>
                      <div>
                        <div>
                          <div>
                            <label className="mb-0 mt-1" style={{fontWeight: "100"}}>
                              {templateButtons && templateButtons.buttons[0].type === "QUICK_REPLY" ? "Respuesta rápida" : "Llamada a la acción"}
                            </label>
                          </div>
                          <ul className={templateButtons && templateButtons.buttons[0].type !== "QUICK_REPLY" ? "d-flex" : null }>
                            {templateButtons && templateButtons.buttons.map((tem, b) => (<>

                              {tem.type === "QUICK_REPLY" ?
                                <div key={b} className="d-flex">
                                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-4">
                                    <label htmlFor="name">Texto del botón:{" "}<span style={{fontWeight: "100"}}>{tem.text}</span></label>
                                  </div>
                                </div>

                              : tem.type === "URL" ?
                                <div key={b} className="" style={{ width: "97%" }}>
                                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-4 w-100 mt-3">
                                    <label htmlFor="name">Tipo de acción:{" "}<span style={{fontWeight: "100"}}>Ir al sitio web</span></label>
                                  </div>
                                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-4 w-100 mt-1">
                                    <label htmlFor="name">Texto del botón:{" "}<span style={{fontWeight: "100"}}>{tem.text}</span></label>
                                  </div>
                                </div>

                              : tem.type === "PHONE_NUMBER" ?
                                <div key={b} className="" style={{ width: "97%" }}>
                                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-4 w-100 mt-3">
                                    <label htmlFor="name">Tipo de acción:{" "}<span style={{fontWeight: "100"}}>Llamar al número de telefono</span></label>
                                  </div>
                                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mr-4 w-100 mt-1">
                                    <label htmlFor="name">Texto del botón:{" "}<span style={{fontWeight: "100"}}>{tem.text}</span></label>
                                  </div>
                                </div>

                              :
                                null
                              }
                            </>))}

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </form>
            </div>

            {/**************** COLUMNA 3 ****************/}
            <div className="colum-3 ml-0">
              <section className="section-preview p-3">
                <h3>Vista previa</h3>
                  {/* <span>Vista previa</span> */}
                <div className="section-div1 ml-2 mr-5">
                  <div className="section-div2">
                    <div className="section-div3">
                      <div className="section-div4">
                        <div className={headerType === "TEXT" ? "header-preview headerText" : "header-preview"}>

                          {headerType === "TEXT" && textHeader}

                          {headerType === "IMAGE" ? (
                            <div className="medio-image custom-media"></div>
                          ) : headerType === "VIDEO" ? (
                            <div className="medio-video custom-media"></div>
                          ) : headerType === "DOCUMENT" ? (
                            <div className="medio-document custom-media"></div>
                          ) : headerType === "LOCATION" ? (
                            <div className="medio-location custom-media"></div>
                          ) 
                          : null}                      

                        </div>

                        {headerType === "LOCATION" && 
                          <div class="data-location">
                            <div class="location-name">{"{{Location name}}"}</div>
                            <div class="location-adress">{"{{Adress}}"}</div>
                          </div>
                        }

                        <div className="section-div5">
                          <span className="span">
                            <span>{templateBody && templateBody}</span>
                          </span>
                        </div>

                        {textFooter && (<div className="content-footer">{textFooter}</div>)}

                        <time aria-hidden="true" className="_6xe5">{dayjs(date).locale("es").format("hh:mm a")}</time>
                      </div>

                      <div className="content-botton">
                        {templateButtons && templateButtons.buttons.map((tem, b) => (<>
                          {tem.type === "PHONE_NUMBER" ? 
                              <div key={b}>
                                  <BsFillTelephoneFill className="mr-1" style={{ fontSize: "1.3rem"}}/>
                                  <span>{tem.text}</span>
                              </div>

                          : tem.type === "URL" ?
                              <div key={b}>
                                <TiExport className="mr-1" style={{ fontSize: "1.3rem"}}/>
                                <span>{tem.text}</span>
                              </div>

                          : null }
                        </>))}
                      </div>

                    </div>

                    {templateButtons && templateButtons.buttons.map((tem, b) => (<>

                      {tem.type === "QUICK_REPLY" ?
                        <div key={b} className="content-fastAnswer">
                          <div>
                            <span>{tem.text}</span>
                          </div>
                        </div>
                        
                      : 
                        null 
                      }

                    </>))}

                    {buttonTextFastAnswer && (
                      <div className="content-fastAnswer">
                        <div>
                          <span>{buttonTextFastAnswer}</span>
                        </div>

                        {buttonTextFastAnswer2 && (
                          <div>
                            <span>{buttonTextFastAnswer2}</span>
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>
              </section>
            </div>
          </div>

          <footer>
            <div className="row">
              <div className="col-sm-6"></div>
              <div className="col-sm-6 right">
                <a href="javascript:;" className="btn btn-lg btn-default mr-1"
                  onClick={() => navigate('/home/templates')}
                >
                  Volver
                </a>
                {/* <input
                  className="btn btn-lg btn-info"
                  value="Guardar"
                  type="submit"
                /> */}
              </div>
            </div>
          </footer>
        </form>
      </section>

    </Fragment>
  );
};

export default TemplateWhatsappEdit;

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
  text-align: left;
`;

const Botton = styled.button`
  color: #000000;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.3;

  &:hover {
    color: #000000;
    opacity: 0.7;
  }
`;
