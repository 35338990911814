import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import useAPI from '../../hooks/useAPI';
import logoLogin from '../../imgs/logo-login.png';
import Spinner from "../plugins/Spinner";


const ForgotPassword = () => {

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {urlAPI, urlAPI_1, urlAPI_2, fetchWithAuth} = useAPI();

  const {watch, register, handleSubmit, formState: {errors}, reset} = useForm();

  const token = localStorage.getItem('USER_TOKEN')

  const postForgot = async values => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "auth/forgotPassword";
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log('Data send', result);
      setLoading(false);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type
      })
      reset();
      navigate('/')
    } catch (error) {
      console.log(error);
    }
  }

  return ( 
    <Fragment>
      {loading ? <div className="content-spinner"><Spinner /></div> : null}
      <div id="main-login">
        <div className="container-fluid">  
          <main id="login-register" className="loggin">
            <header>
                <h1>
                  <a href="#"><img src={logoLogin} alt="Logo" /></a>
                </h1>
            </header>
            <section className="box login">
                <header>
                    <h3>Recuperar contraseña</h3>
                </header>
                <div className="padd">
                    <form onSubmit={handleSubmit(postForgot)}>
                      <span id="idmssg" >
                        <font color="#610B0B">Ingresa tu correo electronico y da click en enviar</font>
                      </span>
                        <label>Correo electrónico</label>
                        <input type="email" id="email" name="email" className="form-control" placeholder="Correo electrónico"
                          {...register("email", {
                            required: {
                              value: true,
                              message: "El email es requerido.",
                            },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "El formato no es correcto",
                            },
                          })}
                        />
                        {errors.email && <Alert>{errors.email.message}</Alert>}
                        <button type="submit" className="btn btn-primary btn-lg" id="btnpassRecover" >Enviar</button>
                        <button type="button" onClick={() => navigate("/" )} className="btn btn-lg btn-default" id="btnReturnRecover" >Volver</button>    
                    </form>            
                </div>
            </section> 
          </main>
        </div>
      </div>
    </Fragment>
  )
}

export default ForgotPassword

const Navigate = styled.p`
 cursor: pointer;
`

const Alert = styled.p`
  color: red;
  font-weight: 900;
  font-size: 15px;
  margin-bottom: 10px;
  text-align: center
`