import React from 'react';
import AutoLinkText from 'react-autolink-text2';

const TextMessage = ({message, isTextError}) => {
  // Dividimos el texto usando expresión regular para capturar palabras entre asteriscos
  const formattedText = message.bodytext.split(/(\*[^*]+\*)/g).map((part, index) => {
    if (part.startsWith('*') && part.endsWith('*')) {
      // Eliminamos los asteriscos y envolvemos el texto en <strong>
      return <strong key={index}>{part.slice(1, -1)}</strong>;
    } else {
      // Para el resto del texto, aplicamos AutoLinkText
      return <AutoLinkText key={index} text={part} linkProps={{ target: '_blank', rel: 'noopener noreferrer' }} />;
    }
  });

  return (
    <span style={{ fontStyle: isTextError ? "italic" : "normal", fontWeight: isTextError ? "700" : "normal" }}>
      {formattedText}
    </span>
  );
};

export default TextMessage;