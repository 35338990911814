import Avvvatars from "avvvatars-react";
import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import useAPI from "../../hooks/useAPI";
import Modal from "../layout/Modal";
import Spinner from "../plugins/Spinner";
import dayjs from "dayjs";
import Header from "../layout/Header";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { FaCcMastercard } from "react-icons/fa6";
import { RiVisaFill} from "react-icons/ri";

const Person = () => {

  const [navAdditionalData, setNavAdditionalData] = useState(false);
  const [openFormWompi, setOpenFormWompi] = useState(false);
  const [navLists, setNavLists] = useState(false);

  const token = localStorage.getItem("USER_TOKEN");

  const [loading, setLoading] = useState(false);
  const [listMethods, setListMethods] = useState([]);

  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  const formEpayco = useForm();
  const error = formEpayco.formState.errors;

  const formWompi = useForm();
  const errorsWompi = formWompi.formState.errors;

  useEffect(() => {
    getMethods()
  }, []);

  const getMethods = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "getPaymentMethods"
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setLoading(false);
      console.log('result---->', result)
      if (result.code == 0) {
        Swal.fire({
          title: "Atención!",
          text: result.message,
          icon: result.type,
        });
        setListMethods([])

      } else {
        setListMethods(result.data.methods)
        const dataEpayco = result.data.methods.filter(data => data.platform === 'Epayco')[0];
        const dataWompi = result.data.methods.filter(data => data.platform === 'Wompi')[0];
        if (dataEpayco) {
          formEpayco.reset({
            p_cust_id_client: dataEpayco.p_cust_id_client,
            private_key: dataEpayco.private_key,
            public_key: dataEpayco.public_key,
            isActive: dataEpayco.isActive
          })
        }

        if (dataWompi) {
          formWompi.reset({
            private_key: dataWompi.private_key,
            isActive: dataWompi.isActive
          });
        }
      }
    } catch (error) {
      console.log('Error---->', error)
    }
  }
  
  const savePaymentMethod = async (data) => {
    // console.log('data---->', formEpayco.getValues(), formWompi.getValues())
    let objData = [];
    if (formEpayco.getValues().private_key !== '' && formEpayco.getValues().public_key !== '') {
      objData.push({...formEpayco.getValues(), platform: 'Epayco'});
    }

    if (formWompi.getValues().private_key !== '') {
      objData.push({...formWompi.getValues(), platform: 'Wompi'});
    }

    try {
      setLoading(true);
      const url = urlAPI_2 + "savePaymentMethods"
      const options = { method: "POST", mode: "cors", body: JSON.stringify(objData)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setLoading(false);
      console.log('result---->', result)
      if (result.code == 0) {
        Swal.fire({
          title: "Atención!",
          text: result.message,
          icon: result.type,
        });
        setListMethods([])

      } else {
        Swal.fire({
          title: "¡Bien!",
          text: result.message,
          icon: result.type,
        });
        setListMethods(result.data.methods)
        const method = result.data.methods[0]

        formEpayco.reset({
          p_cust_id_client: method.p_cust_id_client,
          private_key: method.private_key,
          public_key: method.public_key,
        });
        formWompi.reset({
          private_key_wompi: method.private_key_wompi,
          isActiveWompi: method.isActiveWompi
        });
      }
    } catch (error) {
      console.log('Error---->', error)
    }
  }

  const onAditionalDates = () => {
    setNavLists(false);
    if (navAdditionalData) {
      // console.log("trueeee");
      setNavAdditionalData(false);
    } else {
      // console.log("Falseeee");
      setNavAdditionalData(true);
    }
  };

  const showFormWompi = () => {
    setOpenFormWompi(prevState => !prevState);
  }

  // const onSubmitWompi = (data) => {
  //   savePaymentMethod(data, 'wompi');
  // }

  // const onSubmitEpayco = (data) => {
  //   savePaymentMethod(data, 'epayco');
  // }

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="row mx-auto container-fluid p-0 justify-content-between align-items-center border-top-0 border-right-0 border-left-0 border-secondary mb-3">
        <div>
          {/* <button
            type="button"
            className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex btn-angle-left"
          >
            Medios de pago
          </button> */}
            <h1 style={{fontSize:"1.6em"}}>Medios de pago</h1>
        </div>

        <Header />
      </div>

      <section className="module module-user paymentMethods">
        <div>
          <div className="module-section">
            <div className="row justify-content-center">

              <div className="col-md-12 pl-0">
                <div className="panel-group collapsible" id="user-collapsible">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={onAditionalDates}>
                        <a
                          href="javascript:;"
                          className={`d-flex align-items-center a-payment ${navAdditionalData && "active"}`}
                        >
                          <RiVisaFill className="mr-2" /> Epayco
                        </a>
                        {navAdditionalData ?
                          <FaMinusCircle className="pointer mr-3" style={{ fontSize: "20px", 
                          // marginTop: "5px" 
                        }}/> :
                          <FaPlusCircle className="pointer mr-3" style={{ fontSize: "20px", 
                          // marginBottom: "5px" 
                        }}/>
                        }
                      </h3>
                    </div>

                    {navAdditionalData ? (
                      <div id="email-welcome" className="panel-collapse">
                        <div className="panel-body">
                          <form className="m-0" style={{ width: "100%" }} onSubmit={formEpayco.handleSubmit(savePaymentMethod)}>
                            <div className="d-flex">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable col-md-8">
                                <div className="mb-2">
                                  <label>P_CUST_ID_CLIENTE: </label>
                                  <div className="su-control-group d-flex">
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="answerContent"
                                      maxLength="60"
                                      {...formEpayco.register("p_cust_id_client", {
                                        required: {
                                          value: true,
                                          message:"El P_CUST_ID_CLIENTE es requerido.",
                                        },
                                      })}
                                    />
                                  </div>
                                  {error.p_cust_id_client && <Alert className="w-100">* {error.p_cust_id_client.message}</Alert>}
                                </div>

                                <div className="mb-2">
                                  <label>PUBLIC_KEY: </label>
                                  <div className="su-control-group d-flex">
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="answerContent"
                                      maxLength="60"
                                      {...formEpayco.register("public_key", {
                                        required: {
                                          value: true,
                                          message:"El PUBLIC_KEY es requerido.",
                                        },
                                      })}
                                    />
                                  </div>
                                  {error.public_key && <Alert className="w-100">* {error.public_key.message}</Alert>}
                                </div>

                                <div className="mb-2">
                                  <label>PRIVATE_KEY: </label>
                                  <div className="su-control-group d-flex">
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="answerContent"
                                      maxLength="60"
                                      {...formEpayco.register("private_key", {
                                        required: {
                                          value: true,
                                          message:"El PRIVATE_KEY es requerido.",
                                        },
                                      })}
                                    />
                                    </div>
                                    {error.private_key && <Alert className="w-100">* {error.private_key.message}</Alert>}
                                </div>

                                <div className="mb-2 mt-4">
                                  <div className="d-flex align-items-center">
                                    <input
                                        name="messageCheck"
                                        id="messageCheck"
                                        type="checkbox"
                                        className="cmn-toggle cmn-toggle-round-flat"
                                        {...formEpayco.register("isActive")}
                                    />
                                    <label className="ml-0 mb-0" htmlFor="messageCheck"></label>
                                    <label className="ml-4 mb-0">Activar este medio de pago.</label>
                                  </div>
                                </div>

                              </div>

                              <div className="col-md-4">
                                <div className="su-control su-message message-epayco">
                                    <h4 className="title text-justify">
                                        <i className="icon-info-circled"></i>Epayco
                                    </h4>
                                    <div className="content-message-epayco">
                                        <p className="text-justify mt-0"><strong>ePayco</strong> es una empresa que se especializa en procesar pagos en línea permitiendo a las personas o comercios realizar pagos, cobros y recargas de una manera fácil y segura. Si no tienes una cuenta de ePayco, puedes conseguir una <a href="https://dashboard.epayco.com/login#registro" target="_blank" rel="noreferrer" style={{color:"#5bc0de"}}>aquí</a>.                                   
                                        </p>
                                    </div>
                                </div>
                              </div>

                            </div>

                            <div className="row justify-content-end bd col-12 mt-4">
                              <input
                                className="btn btn-lg btn-info"
                                type="submit"
                                value="Guardar"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/* Método de pago wompi */}
            <div className="row justify-content-center">
              <div className="col-md-12 pl-0">
                <div className="panel-group collapsible" id="user-collapsible">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 id="title-aditionalDates" className="panel-title d-flex justify-content-between align-items-center pointer" onClick={showFormWompi}>
                        <a
                          href="javascript:;"
                          className={`d-flex align-items-center a-payment ${openFormWompi && "active"}`}
                        >
                          <FaCcMastercard className="mr-2" /> Wompi
                        </a>
                        {openFormWompi ?
                          <FaMinusCircle className="pointer mr-3" style={{ fontSize: "20px", 
                          // marginTop: "5px" 
                        }}/> :
                          <FaPlusCircle className="pointer mr-3" style={{ fontSize: "20px", 
                          // marginBottom: "5px" 
                        }}/>
                        }
                      </h3>
                    </div>

                    {openFormWompi ? (
                      <div id="email-welcome" className="panel-collapse">
                        <div className="panel-body">
                          <form className="m-0" style={{ width: "100%" }} onSubmit={formWompi.handleSubmit(savePaymentMethod)}>
                            <div className="d-flex">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable col-md-8">
                                <div className="mb-2">
                                  <label>PRIVATE_KEY: </label>
                                  <div className="su-control-group d-flex">
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="answerContent"
                                      maxLength="60"
                                      {...formWompi.register("private_key", 
                                        {
                                          required: {
                                            value: true,
                                            message:"El PRIVATE_KEY es requerido.",
                                          },
                                        }
                                      )}
                                    />
                                    </div>
                                    {errorsWompi.private_key && <Alert className="w-100">* {errorsWompi.private_key.message}</Alert>}
                                </div>

                                <div className="mb-2 mt-4">
                                  <div className="d-flex align-items-center">
                                    <input
                                        name="messageCheckWompi"
                                        id="messageCheckWompi"
                                        type="checkbox"
                                        className="cmn-toggle cmn-toggle-round-flat"
                                        {...formWompi.register("isActive")}
                                    />
                                    <label className="ml-0 mb-0" htmlFor="messageCheckWompi"></label>
                                    <label className="ml-4 mb-0">Activar este medio de pago.</label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="su-control su-message message-epayco">
                                    <h4 className="title text-justify">
                                        <i className="icon-info-circled"></i>Wompi
                                    </h4>
                                    <div className="content-message-epayco">
                                        <p className="text-justify mt-0"><strong>Wompi</strong> es una empresa que se especializa en procesar pagos en línea permitiendo a las personas o comercios realizar pagos, cobros y recargas de una manera fácil y segura. Si no tienes una cuenta de <strong>Wompi</strong>, puedes conseguir una <a href="https://registro.wompi.co/aggregator/register/trade-type" target="_blank" rel="noreferrer" style={{color:"#5bc0de"}}>aquí</a>.                                   
                                        </p>
                                    </div>
                                </div>
                              </div>

                            </div>

                            <div className="row justify-content-end bd col-12 mt-4">
                              <input
                                className="btn btn-lg btn-info"
                                type="submit"
                                value="Guardar"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Fragment>
  );
};

export default Person;

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;
