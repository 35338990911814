import React, { useEffect,useRef } from 'react';
import ReactPlayer from "react-player";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { FaHeadphones, FaTimes } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import { BsFillCameraVideoFill, BsFillFileEarmarkFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkWordFill, BsFileEarmarkExcelFill, BsFillFileEarmarkFontFill } from "react-icons/bs";
import { useState } from 'react';

const PreviewMediaToSend = ({
    isMediaAnswer,
    isMediaTypeAnswer,
    selectedImg,
    fileType,
    documentView,
    mediaSelected,
    selectedFiles,
    textDocument,
    inputRef,
    idAnswerSelected,
    setSelectedImg,
    setMediaSelected,
    setTextDocument,
    setIsMedia,
    setFileType,
    setDocumentView,
    setContenMessage,
    setSelectedFiles,
    dataFilesRef,
    focusLastMessage,
    handleRemoveFile,
    // keyDown,
    onSendMessageMedia,
}) => {
    const divRef = useRef(null);

    const keyDown = (event) => {
        if (event.keyCode === 13) {
            console.log('PRESIONANDO ENTER ...');
            setSelectedImg("")
            setMediaSelected(0)
            // onClick for multimedia (images, video, mp3, etc)
            if (isMediaAnswer) {
                onSendMessageMedia(idAnswerSelected);
            } else {
                onSendMessageMedia();
            }
            setTimeout(function(){
                focusLastMessage("template-3822");
            }, 200);
        }
    };
    
    useEffect(() => {
        if (divRef.current) {
            divRef.current.focus();
        }
    }, []);

  return (
    <div 
        className="p-3 w-100" 
        style={{height:"93%"}} 
        onKeyDown={keyDown}
        tabIndex={0} 
        ref={divRef}
    >
        <div style={{height:"4%"}}>
            <IoClose
                className="icon-cancel pointer"
                style={{ fontSize: "1.8rem" }}
                onClick={() => {
                    setSelectedImg("")
                    setMediaSelected(0)
                    setTextDocument("");
                    setIsMedia((isMedia) => { return false });
                    setFileType((fileType) => { return "" });
                    dataFilesRef.current = [];

                    setTimeout(function(){
                        focusLastMessage("template-3746");
                    }, 200);

                }}
            />
        </div>

        <div className="content-imgs bg-white d-flex justify-content-center align-items-center px-4 py-2" style={{ height: "88%", overflow: "auto"}}>
            <div className="preview-container h-100">
            {isMediaAnswer ? (
                <div className="w-100 h-100">
                    <div className="preview-item-big h-100" style={{width:"75%", margin:"0 auto"}}>
                        {isMediaTypeAnswer === "image" ?
                            <img src={isMediaAnswer} alt="img" className="h-100 w-100" style={{objectFit:"contain"}}/> : 
                            <iframe src={isMediaAnswer} className="w-100 h-100"></iframe>
                        }
                    </div>
                </div>
            ): (
                <div className="w-100 h-100">
                    {selectedImg && (
                        <>
                            {fileType === 'document' || fileType === 'audio' ? (
                                <div 
                                    id="content-view-documents" 
                                    className={`${!documentView.name.includes(".pdf") ? "w-50 m-auto text-center d-flex align-items-center justify-content-center" : "w-50 m-auto text-center" }`} 
                                    style={{ height: !documentView.name.includes(".pdf") && "49vh", flexDirection: !documentView.name.includes(".pdf") && "column" }}
                                >
                                    {documentView.name.includes(".docx") || documentView.name.includes(".doc") ? (
                                        <BsFillFileEarmarkWordFill style={{ fontSize: "111px"}}/>
                                    ) : documentView.name.includes(".csv") || documentView.name.includes(".xlsx") || documentView.name.includes(".xls") ? (
                                        <BsFileEarmarkExcelFill style={{ fontSize: "111px"}}/>
                                    ) : documentView.name.includes(".txt") ? (
                                        <BsFillFileEarmarkFontFill style={{ fontSize: "111px"}}/>
                                    ) : documentView.name.includes(".pdf") ? (
                                        <div id="content-view-pdf" style={{ height: "51vh" }}>
                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                            <Viewer fileUrl={documentView.src} style={{ width: "100%" }} />
                                        </Worker>
                                        </div>
                                    ) : documentView.name.includes(".m4a") ? (
                                        <FaHeadphones style={{ fontSize: "111px"}}/>
                                    ) : (
                                        <BsFillFileEarmarkFill style={{ fontSize: "111px"}}/>
                                    )}
                                    <br />
                                    {!documentView.name.includes(".pdf") && 
                                        <label style={{ fontSize: "2em" }}>
                                        No hay vista previa disponible
                                        </label>
                                    }
                                    <p className="text-center">
                                        {documentView.name} - {documentView.size}
                                    </p>
                                </div>
                            ) : fileType === 'video' ? (
                                <ReactPlayer style={{margin:"auto"}} url={documentView.src} loop controls width="70%" height="47vh" />
                            ) : (
                                <div className="image-viewer">
                                    <img src={selectedImg} alt="Selected" className="w-100" style={{height:"25rem", objectFit:"scale-down"}} />
                                </div>
                            )}
                        </>
                    )}
                    <div className="d-flex justify-content-center w-100 mt-3 pt-2" style={{borderTop:"1px solid"}}>
                        {selectedFiles.map((file_data, index_file) => (
                            <div key={index_file} className="preview-item-container">
                                {fileType === 'video' && (
                                    <div onClick={() => {setMediaSelected(index_file); setDocumentView(file_data)}}>
                                        <div className={`content-icon-documents p-1 ${mediaSelected === index_file && "media-selected"}`} style={{width:"52px", height:"52px"}}>
                                            <BsFillCameraVideoFill className="w-100 h-100" />
                                        </div>
                                    </div>
                                )}
                                {fileType === 'image' && (
                                    <div className={`preview-item ${mediaSelected === index_file && "media-selected"}`}>
                                        <img src={file_data.src} alt={file_data.name} onClick={() => {setMediaSelected(index_file);setSelectedImg(file_data.src)}}/>
                                    </div>
                                )}
                                {fileType === 'document' && (
                                    <div onClick={() => {setMediaSelected(index_file);setDocumentView(file_data)}}>
                                        <div className={`content-icon-documents p-1 ${mediaSelected === index_file && "media-selected"}`} style={{width:"52px", height:"52px"}}>
                                            {file_data.name.includes(".docx") || file_data.name.includes(".doc") ? (
                                                <BsFillFileEarmarkWordFill className="w-100 h-100" style={{color:"#31acf6"}}/>
                                            ) : file_data.name.includes(".csv") || file_data.name.includes(".xlsx") || file_data.name.includes(".xls") ? (
                                                <BsFileEarmarkExcelFill className="w-100 h-100" style={{color:"#00d591"}}/>
                                            ) : file_data.name.includes(".txt") ? (
                                                <BsFillFileEarmarkFontFill className="w-100 h-100"/>
                                            ) : file_data.name.includes(".pdf") ? (
                                                <BsFillFileEarmarkPdfFill className="w-100 h-100" style={{color: "red"}}/>
                                            ) : (
                                                <BsFillFileEarmarkFill className="w-100 h-100"/>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {fileType === 'audio' && (
                                    <div onClick={() => {setMediaSelected(index_file);setDocumentView(file_data)}}>
                                        <div className={`content-icon-documents p-1 ${mediaSelected === index_file && "media-selected"}`} style={{width:"52px", height:"52px"}}>
                                            <FaHeadphones className="w-100 h-100"/>
                                        </div>
                                    </div>
                                )}
                                
                                <button className="remove-button" onClick={() => handleRemoveFile(index_file)}><FaTimes /></button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            </div>
        </div>


        <div className="mt-0 su-control su-segmentation-disable d-flex justify-content-end align-items-center" style={{ width: "87%", margin: "0 auto", height: "8%" }}>
            {selectedFiles.length == 1 && ( fileType == "image" || selectedFiles[0].name.includes(".pdf")) || isMediaAnswer ? (
                <input
                    spellCheck="true"
                    placeholder="Escribe aquí"
                    className="form-control px-1"
                    type="text"
                    name="name"
                    id="name"
                    value={textDocument}
                    maxLength="4096"
                    onKeyDown={keyDown}
                    ref={inputRef}
                    onChange={(e) => {
                        setContenMessage(e.target.value);
                        setTextDocument(e.target.value);
                    }}
                />
            ): null}

            <span className='d-flex ml-2 w-auto' style={{backgroundColor:"#25d366", padding:"8px 6px 8px 11px", borderRadius:"50%"}}>
                <IoMdSend id='icon-send-messages' className="send pointer" style={{ fontSize: "27px" }}
                    onClick={() => {
                        setSelectedImg("")
                        setMediaSelected(0)
                        // onClick for multimedia (images, video, mp3, etc)
                        if (isMediaAnswer) {
                            onSendMessageMedia(idAnswerSelected);
                        } else {
                            onSendMessageMedia();
                        }
                        setTimeout(function(){
                            focusLastMessage("template-3822");
                        }, 200);
                    }}
                />
            </span>
        </div>

    </div>
  );
};

export default PreviewMediaToSend;