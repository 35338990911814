import Avvvatars from "avvvatars-react";
import dayjs from "dayjs";
import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useAPI from "../../hooks/useAPI";
import usePage from "../../hooks/usePage";
import Pagination from "../plugins/Pagination";
import Spinner from "../plugins/Spinner";
import Header from "../layout/Header";
import { BsSearch, BsPencilFill, BsTrash3 } from "react-icons/bs";
import { FaAngleLeft } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";

const UsersSystem = () => {
  const [mode, setMode] = useState("list");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [usersSystem, setUsersSystem] = useState([]);
  const [tableUsersSystem, setTableUsersSystem] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolSelected, setRolSelected] = useState([]);
  const [areas, setAreas] = useState([]);
  const [userSystem, setUserSystem] = useState({});
  const [validation, setValidation] = useState(false);
  const [edit, setEdit] = useState(false);
  const [plan, setPlan] = useState([]);
  const [cantAgents, setCantAgents] = useState("");
  
  const [selectedRadio, setSelectedRadio] = useState("1");
  const radioOptions = [
    {id: "1", name: "Todo"},
    {id: "2", name: "Area"},
  ]

  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      rol: "",
      status: true,
      area: "",
      supervise: "1"
    },
  });

  const { urlAPI, urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();
  const { page, byPage, resetPage, setPage } = usePage();

  const companyPlan = localStorage.getItem("COMPANY_PLAN");

  useEffect(() => {
    getUsersSystem();
    getRoles();
    getAreas();
    getPlans();
    reset({supervise: "1"})
  }, []);

  const getPlans = async () => {
    try {
      const url = urlAPI_2 + "plans";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API: Get Types: Plans", result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else {
        console.log("Response of companyPlan", companyPlan);
        const planSelected = result.data.filter(plan => plan.id === companyPlan)
        console.log("Response of the Filter: Plans", planSelected[0]);
        setPlan(planSelected[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getUsersSystem = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "users";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API", result);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      } else if (
        result.message === "Tu petición no tiene cabecera de autorización"
      ) {
        Swal.fire({
          title: "¡Atención!",
          text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
          icon: "info",
        });
        navigate("/");
      } else if (!result.data) {
        setValidation(true);
        setUsersSystem([]);
      } else {
        setUsersSystem(result.data);
        setTableUsersSystem(result.data);
        setValidation(false);
        localStorage.setItem("ID_COMPANY", result.idCompany);
        const filterAgents = result.data.filter(agents => agents.rolId != "6284fdd697029c6d9743ccb3").length;
        setCantAgents(filterAgents);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getRoles = async () => {
    try {
      const url = urlAPI_2 + "roles";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of GET ROlES", result);
      setRoles(result.data);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAreas = async () => {
    try {
      const url = urlAPI_2 + "getArea";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response GET AREAS", result);
      setAreas(result.data);
      if (result.code === 0) {
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: result.type,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  const postUserSystem = async (inputs) => {
    const { name, email, password, confirmPassword, rol, status, area, supervise } = inputs;
    const values = {
      name,
      email,
      password,
      rol,
      status,
      area,
      supervise,
    };
    console.log("=====values=====", values);
    console.log("=====cantAgents=====", cantAgents);
    console.log("=====plan.number_agents=====", plan);

    if (password === confirmPassword) {
      const createUser = async () => {
        console.log("Creando...........");

        try {
          setLoading(true);
          const url = urlAPI_2 + "users";
          const options = { method: "POST", mode: "cors", body: JSON.stringify(values)};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          console.log("Response of the API", result);
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
          });
          if (
            result.message === "Tu petición no tiene cabecera de autorización"
          ) {
            Swal.fire({
              title: "¡Atención!",
              text: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales",
              icon: "info",
            });
            navigate("/");
          }
          if (result.type !== "warning") {
            reset();
            setMode("list");
            getUsersSystem();
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
      const showAlert = async (title) => {
        Swal.fire({
          title: title,
          text: `Si lo creas, tendras un cobro adicional de $USD ${plan.additionalAgentPrice} mensuales en tu factura`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Crear",
          cancelButtonText: "Cancelar", 
        }).then(async (result) => {
          if (result.isConfirmed){
            await createUser();
          }
        })
      }
      if ((rol === "6284fdfc97029c6d9743ccb4" || rol === "64886b1b250f9d31bf2ed68d") && cantAgents >= plan.number_agents) {
        console.log('YA tiene el límete de agentes, se le cobrará...')
        showAlert("Ya cuentas con el límite de agentes según tu plan")
        return;
      } else if (rol === "6284fdd697029c6d9743ccb3" && cantAgents >= plan.number_administrators) {
        console.log('YA tiene el limete de administradores, se le cobrará...')
        showAlert("Ya cuentas con el límite de administradores según tu plan")
        return;
      }
      await createUser();

    } else {
      Swal.fire({
        title: "¡Atención!",
        text: "Las contraseñas no coinciden.",
        icon: "warning",
      });
    }
  };

  const getUserSystem = async (id) => {
    try {
      const url = urlAPI_2 + "users/" + id;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API", result);
      setUserSystem(result.data);
      const { name, email, rolId, status, area } = result.data;
      reset({
        name,
        email,
        rol: rolId,
        status,
        area,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const putUserSystem = async (inputs) => {
    const { name, email, rol, status, area } = inputs;
    const values = {name,email,rol,status,area,};
    try {
      setLoading(true);
      const url = urlAPI_2 + "users/" + userSystem._id;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API", result);
      Swal.fire({
        title: "¡Bien!",
        text: result.message,
        icon: result.type,
      });
      clearInputs();
      setMode("list");
      setEdit(false);
      getUsersSystem();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUserSystem = async (id) => {
    Swal.fire({
      title: "Estas seguro que deseas eliminar este usuario?",
      text: "No podrás revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar", 
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const url = urlAPI_2 + "users/" + id;
          const options = { method: "DELETE", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          console.log("Response of the API", result);
          Swal.fire({
            title: "¡Bien!",
            text: result.message,
            icon: result.type,
          });
          getUsersSystem();
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const searchQuery = (searchTerm) => {
    var searchResults = tableUsersSystem.filter(element => {
      if (
        element.name
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        element.email
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return element;
      }
    });
    setUsersSystem(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const formatUsersSystem = [...usersSystem].reverse();

  const clearInputs = () => {
    reset({ name: "", email: "", rol: "", status: true, supervise: "1"});
    setSelectedRadio("1")
  };

  const roleDescription = localStorage.getItem("USER_ROLEDESCRIPTION");
  // console.log(roleDescription)

  setTimeout(() => {
    if (roleDescription !== "Administrador") {
      return navigate("/home");
    }
  }, 1);

  const { status } = watch();
  const { rol } = watch();
  const statusBool = Boolean(status);
  const statusFinal = statusBool ? 'activo' : 'inactivo' ;

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      {mode === "create" ? (
        <>
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
            <div style={{ height: "45px", lineHeight: "95px" }}>
              <button
                onClick={() => {
                  setMode("list");
                  setEdit(false);
                }}
                type="button"
                className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex align-items-center"
              >
                <FaAngleLeft /> Agentes
              </button>
            </div>

            <Header />
          </div>

          <section className="module-create-user">
            <header className="message">
              <div id="tittleFormProduct">
                <h1>{edit ? "Editar agente" : "Crear agente"}</h1>
              </div>
            </header>
            <div>
              <form onSubmit={edit ? handleSubmit(putUserSystem) : handleSubmit(postUserSystem)}>
                <div className="module-section" id="product-basic">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Nombre: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="name"
                                type="text"
                                {...register("name", {
                                  required: {
                                    value: true,
                                    message: "El nombre es obligatorio.",
                                  },
                                })}
                              />
                            </div>
                            {errors.name && (
                              <p className="text-danger mt-1 f-w-900">
                                * {errors.name.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Correo electronico: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="email"
                                type="email"
                                {...register("email", {
                                  required: {
                                    value: true,
                                    message:
                                      "El correo electrónico es requerido.",
                                  },
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message:
                                      "El formato del correo no es correcto",
                                  },
                                })}
                              />
                            </div>
                            {errors.email && (
                              <p className="text-danger mt-1 f-w-900">
                                * {errors.email.message}
                              </p>
                            )}
                          </div>
                        </div>

                        {edit ? null : (
                          <>
                            <div className="col-md-6">
                              <div className="su-control su-segmentation-disable">
                                <label>Contraseña: </label>
                                <input
                                  className="form-control"
                                  id="password"
                                  type="password"
                                  {...register("password", {
                                    required: {
                                      value: true,
                                      message: "La contraseña es obligatoria.",
                                    },
                                  })}
                                />
                              </div>
                              {errors.password && (
                                <p className="text-danger mt-1 f-w-900">
                                  * {errors.password.message}
                                </p>
                              )}
                            </div>
                            <div className="col-md-6">
                              <div className="su-control su-segmentation-disable">
                                <label>Confirmar contraseña: </label>
                                <input
                                  className="form-control"
                                  id="confirmPassword"
                                  type="password"
                                  {...register("confirmPassword", {
                                    required: {
                                      value: true,
                                      message:
                                        "Se necesita confirmar la contraseña.",
                                    },
                                  })}
                                />
                              </div>
                              {errors.confirmPassword && (
                                <p className="text-danger mt-1 f-w-900">
                                  * {errors.confirmPassword.message}
                                </p>
                              )}
                            </div>
                          </>
                        )}

                        <div className={(rol === "6284fdfc97029c6d9743ccb4" || (rol === "64886b1b250f9d31bf2ed68d" && selectedRadio === "1")) ? "col-md-4" : (rol === "64886b1b250f9d31bf2ed68d" && selectedRadio === "2") ? "col-md-3" : "col-md-6"} >
                          <div className="su-control su-segmentation-disable">
                            <label>Rol: </label>
                            <select
                              name="rol"
                              // id={rol._id}
                              className="form-control"
                              {...register("rol", {
                                required: {
                                  value: true,
                                  message: "El rol es obligatorio",
                                },
                              })}
                            >
                              <option value="" key="0" disabled selected>
                                Seleccione...
                              </option>
                              {roles.map((rol, k) => (
                                <option value={rol._id} key={k}>
                                  {rol.description}
                                </option>
                              ))}
                            </select>
                            {/* <div className="d-flex justify-content-center mt-2 rolType">
                              {roles.map((rol, k) => (
                                <div key={k}>
                                  <input
                                    type="radio"
                                    name="rol"
                                    id={rol._id}
                                    className="form-check-input mt-3"
                                    value={rol._id}
                                    {...register("rol", {
                                      required: {
                                        value: true,
                                        message: "El rol es obligatorio",
                                      },
                                    })}
                                  />
                                  <label
                                    htmlFor={rol._id}
                                    className="form-check d-flex"
                                    key={rol.id}
                                  >
                                    <div>
                                      <label
                                        htmlFor={rol._id}
                                        className="form-check-label f-18 f-w-400"
                                      >
                                        {rol.description}
                                      </label>
                                    </div>
                                  </label>
                                </div>
                              ))}
                            </div> */}
                            {errors.rol && (<p className="text-danger f-w-900">* {errors.rol.message}</p>)}
                          </div>
                        </div>

                        {rol === "64886b1b250f9d31bf2ed68d" && (
                          <div className={(rol === "6284fdfc97029c6d9743ccb4" || (rol === "64886b1b250f9d31bf2ed68d" && selectedRadio === "1")) ? "col-md-4" : (rol === "64886b1b250f9d31bf2ed68d" && selectedRadio === "2") ? "col-md-3" : "col-md-6"}>
                            <div className="su-control su-segmentation-disable text-center">
                              <label>Supervisar: </label>

                              <div className="su-control mt-2 d-flex justify-content-evenly">
                                {radioOptions.length > 0 && radioOptions.map((radio, k) => (
                                  <div key={k} className="d-flex pointer radio">
                                    <input
                                      type="radio"
                                      name="supervise"
                                      id={radio.id}
                                      value={radio.id}
                                      checked={selectedRadio === radio.id}
                                      className="pointer"
                                      {...register("supervise")}
                                      onChange={(e) => {
                                        console.log('-------radio--------', e.target.value)
                                        setSelectedRadio(e.target.value);
                                      }}
                                    />
                                    <label htmlFor={radio.id} className="d-flex mb-0 ml-1 pointer radio-label" key={radio.id} style={{ padding: "5px 2px 5px" }}>
                                      {radio.name}
                                    </label>
                                  </div>
                                ))}
                              </div>

                              {/* <div className="su-control su-switch mt-2">
                                <div className="switch">
                                  <input
                                    id="areaAsign"
                                    className="cmn-toggle cmn-toggle-round-flat"
                                    type="checkbox"
                                    name="areaAsign"
                                    {...register("areaAsign")}
                                  />
                                  <label htmlFor="areaAsign"></label>
                                </div>
                                <label
                                  className="form-check-label f-18 f-w-400 ml-3"
                                  htmlFor="areaAsign"
                                >
                                  {areaAsignBool === true
                                    ? "Agente activo"
                                    : "Agente inactivo"}
                                </label>
                              </div> */}
                              {errors.status && (<p className="text-danger f-w-900">* {errors.status.message}</p>)}
                            </div>
                          </div>
                        )}

                        {(rol === "6284fdfc97029c6d9743ccb4" || selectedRadio === "2") && (
                          <div className={rol === "6284fdfc97029c6d9743ccb4" ? "col-md-4" : "col-md-3"}>
                            <div className="su-control su-segmentation-disable">
                              <label>Area: </label>
                              <div className="d-flex justify-content-center rolType">
                                <select
                                  name="area"
                                  id="area"
                                  className="form-control mb-1"
                                  // disabled={rol === "6284fdfc97029c6d9743ccb4" ? false : true}
                                  style={{ width: "100%" }}
                                  {...register("area", {
                                    required: {
                                      value: true,
                                      message:
                                        "El area para el agente es obligatoria.",
                                    },
                                  })}
                                >
                                  <option value="" key="0" disabled selected>
                                    Seleccione...
                                  </option>
                                  {areas &&
                                    areas.map((area, a) => (
                                      <option value={area._id} key={a}>
                                        {area.description}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              {errors.area && (<p className="text-danger mt-1 f-w-900">* {errors.area.message}</p>)}
                            </div>
                          </div>
                        )}

                        <div className={(rol === "6284fdfc97029c6d9743ccb4" || (rol === "64886b1b250f9d31bf2ed68d" && selectedRadio === "1")) ? "col-md-4" : (rol === "64886b1b250f9d31bf2ed68d" && selectedRadio === "2") ? "col-md-3" : "col-md-6"}>
                          <div className="su-control su-segmentation-disable text-center">
                            <label>Estado: </label>

                            <div className="su-control su-switch mt-2">
                              <div className="switch">
                                <input
                                  id="status"
                                  className="cmn-toggle cmn-toggle-round-flat"
                                  type="checkbox"
                                  name="status"
                                  // defaultValue={true}
                                  {...register("status")}
                                />
                                <label htmlFor="status"></label>
                              </div>
                              <label
                                className="form-check-label f-18 f-w-400 ml-3"
                                htmlFor="status"
                              >
                                {rol === '6284fdfc97029c6d9743ccb4' ? `Agente ${statusFinal}` : rol === "64886b1b250f9d31bf2ed68d" ? `Supervisor ${statusFinal}` : `Administrador ${statusFinal}`}
                                {/* {statusBool === true ? {rol === '6284fdfc97029c6d9743ccb4' ? 'Agente activo' : `activo`} : "Agente inactivo"} */}
                              </label>
                            </div>
                            {errors.status && (
                              <p className="text-danger f-w-900">
                                * {errors.status.message}
                              </p>
                            )}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <footer>
                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 right">
                      <a
                        href="javascript:;"
                        className="btn btn-lg btn-default mr-1"
                        onClick={() => {
                          setMode("list");
                          setEdit(false);
                        }}
                      >
                        Volver
                      </a>

                      <input
                        className="btn btn-lg btn-info"
                        value="Guardar"
                        type="submit"
                      />
                    </div>
                  </div>
                </footer>
              </form>
            </div>
          </section>
        </>
      ) : (
        <>
          <div className="m-auto container-fluid p-0">
            <div className="col-xs-9 heading">
              <div>
                <h1>Agentes</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Agregar Nuevo"
                  onClick={() => {
                    setMode("create");
                    clearInputs();
                  }}
                />
                {/* <button id="inventory-button" className="btn btn-default ml-1" onClick={() => {setViewToken(true)}}>Ver token</button> */}
              </div>

              <Header />
            </div>

            <header>
              <div style={{ float: "right" }}>
                <form className="form-inline">
                  <div className="su-control mt-1">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Búsqueda"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        searchQuery(e.target.value);
                        resetPage();
                      }}
                    />
                    <button
                      className="btn btn-info"
                      type="button"
                      id="buttonSearchProductBack"
                      style={{ height: "34px" }}
                    >
                      <BsSearch />
                      {/* <FontAwesomeIcon icon={faMagnifyingGlass} /> */}
                    </button>
                  </div>
                </form>
              </div>
            </header>

            <div
              className="container-fluid mt-3 px-0 div-table"
              style={{ height: "580px", overflow: "auto" }}
              // style={{ height: "460px", overflow: "auto" }}
            >
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Acciones</th>
                    <th></th>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Rol</th>
                    <th>Área</th>
                    <th>Estado</th>
                    <th>Fecha Creación</th>
                  </tr>
                </thead>
                <tbody style={{ width: "1170px" }}>
                  {formatUsersSystem.length > 0 &&
                    formatUsersSystem
                      .slice((page - 1) * byPage, (page - 1) * byPage + byPage)
                      .map((userSystem) => {
                        const initialWords = userSystem.name.split(" ");
                        const firstWord = initialWords[0];
                        const secondWord = initialWords[1];
                        let icon = "";
                        if (secondWord === undefined) {
                          icon = firstWord[0];
                        } else {
                          icon = firstWord[0] + secondWord;
                        }

                        return (
                          <tr key={userSystem._id} style={{ height: "64px" }}>
                            <td>
                              <div className="d-flex align-items-center justify-content-center">
                                {/* Acciones en dropdown */}
                                <div className="dropdown">
                                  <button
                                    onClick={() =>
                                      getUserSystem(userSystem._id)
                                    }
                                    className="bg-transparent border-0"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <FaEllipsisVertical className="text-success f-24 pointer" />
                                  </button>
                                  <div
                                    className="dropdown-menu p-2"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <button
                                      onClick={() => {
                                        setMode("create");
                                        setEdit(true);
                                      }}
                                      className="text-primary border-0 bg-transparent d-flex align-items-center pointer mt-2"
                                    >
                                      <BsPencilFill />
                                      <span className="text-dark ml-2 f-14">
                                        Editar
                                      </span>
                                    </button>
                                    <button
                                      onClick={() =>
                                        deleteUserSystem(userSystem._id)
                                      }
                                      className="text-danger border-0 bg-transparent d-flex align-items-center pointer mt-2"
                                    >
                                      <BsTrash3 />
                                      <span className="text-dark ml-2 f-14">
                                        Eliminar
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="icon-avatar">
                              <Avvvatars value={icon} />
                            </td>
                            <td>
                              {/* {userSystem.name} */}
                              {userSystem.name.length >= 25
                                ? userSystem.name.substr(0, 25) + "..."
                                : userSystem.name}
                            </td>
                            <td>
                              {/* {userSystem.email} */}
                              {userSystem.email.length >= 25
                                ? userSystem.email.substr(0, 25) + "..."
                                : userSystem.email}
                            </td>
                            <td>{userSystem.rolName}</td>
                            <td>{userSystem.areaName ? userSystem.areaName : "-"}</td>
                            <td className="status-campaign">
                              {/* {userSystem.status === true ? 'Activo' : 'Inactivo'} */}
                              <p>
                                <a
                                  href="javascript:;"
                                  className={
                                    userSystem.status ? "green" : "gray"
                                  }
                                >
                                  {userSystem.status === true
                                    ? "Activo"
                                    : "Inactivo"}
                                </a>
                              </p>
                            </td>
                            <td>
                              {dayjs(userSystem.created)
                                .locale("es")
                                .format("DD MMMM YYYY")}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              {validation && (
                <h1 className="text-center mt-3">
                  No existen registros en el sistema
                </h1>
              )}
            </div>

            <footer>
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4">
                  {/* Creación de la paginación simple */}
                  <Pagination
                    totalData={formatUsersSystem.length}
                    byPage={byPage}
                    setPage={setPage}
                  />
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default UsersSystem;
